// Analyst.js
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import MetaTags from "react-meta-tags";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import DateFilter from "../../components/Common/DateFilter";
import PlatformSelector from "../../components/Common/PlatformSelector";
import TeamSelector from "../../components/Common/TeamSelector";
import LeagueSelector from "../../components/Common/LeaugueSelector";
import SummaryStatistics from "../../components/Common/SummaryStatistic";
import GoalsChart from "../../components/Common//GoalsChart";

import Loader from "pages/Utility/Loader";
import TopGamesCard from "./TopGamesCard";
import GameCard from "components/Common/GameCard";


const API_URL = process.env.REACT_APP_BASE_URL;

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use((req) => {
  if(localStorage.getItem('profile')){
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    req.headers.Accept = 'application/json';
  }
  return req;
});

const Analyst = () => {
  const [loading, setLoading] = useState(true);
  const [games, setGames] = useState([]);
  const [topGames, setTopGames] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);
  const [showGameModal, setShowGameModal] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedLeague, setSelectedLeague] = useState(null);
  

  useEffect(() => {
    fetchGames();
    fetchTopGames();
  }, []);

  const fetchGames = async (country, date, platform, team) => {
    try {
      const response = await axiosApi.get('/games', { params: { country, date, platform, team } });
      setGames(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching games:', error);
      toast.error('Failed to fetch games');
      setLoading(false);
    }
  };

  const fetchTopGames = async () => {
    try {
      const response = await axiosApi.get('/top-games');
      setTopGames(response.data);
    } catch (error) {
      console.error('Error fetching top games:', error);
      toast.error('Failed to fetch top games');
    }
  };

  const handleGameClick = (game) => {
    setSelectedGame(game);
    setShowGameModal(true);
  };

  const closeGameModal = () => {
    setShowGameModal(false);
    setSelectedGame(null);
  };

  const handleFilterChange = (country, date) => {
    setLoading(true);
    fetchGames(country, date, selectedPlatform, selectedTeam);
  };

  const handlePlatformSelect = (platformId) => {
    setSelectedPlatform(platformId);
    setLoading(true);
    fetchGames(null, null, platformId, selectedTeam);
  };

  const handleTeamSelect = (teamId) => {
    setSelectedTeam(teamId);
    setLoading(true);
    fetchGames(null, null, selectedPlatform, teamId);
  };

  const handleLeagueSelect = (leagueId) => {
    setSelectedLeague(leagueId);
    setLoading(true);
    fetchGames(null, null, selectedPlatform, selectedTeam, leagueId);
  };


  return (
    
     <>
       <MetaTags>
        <title>Analyst Dashboard | Sports Analytics</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <Row className="mb-4">
            <Col lg="3">
              <DateFilter onFilterChange={handleFilterChange} />
            </Col>
            <Col lg="3">
              <PlatformSelector
                selectedPlatform={selectedPlatform}
                onSelectPlatform={handlePlatformSelect}
              />
            </Col>
            <Col lg="3">
              <TeamSelector
                selectedTeam={selectedTeam}
                onSelectTeam={handleTeamSelect}
              />
            </Col>
            <Col lg="3">
              <LeagueSelector
                selectedLeague={selectedLeague}
                onSelectLeague={handleLeagueSelect}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Analyst Dashboard</h4>
                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      <Row>
                        <Col lg="12" className="mb-4">
                          <SummaryStatistics games={games} />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12" className="mb-4">
                          <GoalsChart games={games} />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <TopGamesCard games={topGames} />
                        </Col>
                        <Col lg="8">
                          <Row>
                            {games.map((game) => (
                              <Col key={game.id} lg="6" className="mb-4">
                                <GameCard
                                  game={game}
                                  onClick={() => handleGameClick(game)}
                                />
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </>
  );
};

export default Analyst;
import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useMemo } from "react";
import MetaTags from "react-meta-tags";
import { ToastContainer, toast } from 'react-toastify';
import ReportCard from "components/Common/ReportCard"
import 'moment-timezone';
import NumberFormat from 'react-number-format';
import Loader from "pages/Utility/Loader";

import GeneralCard from "../../components/Common/GeneralCard"




import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Label,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Button,
  Alert
} from "reactstrap";
import { success } from "toastr";


const API_URL = process.env.REACT_APP_BASE_URL


const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use((req) => {
  if(localStorage.getItem('profile')){
      req.headers.Authorization = `Bearer ${ JSON.parse(localStorage.getItem('profile')).token}`,
      req.headers.Accept = 'application/json'
  }
  return req;
});



const Dashboard = props => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")))
  const [totalLosses, setTotalLoses ] = useState(localStorage.getItem('totalLosses') || 0)
  const [totalProfit, setTotalProfit] = useState(localStorage.getItem('profit') || 0)
  const [gameTarget, setGameTarget] = useState(localStorage.getItem('game_target') || 0)
  const [lossCount, setLossCount] = useState(localStorage.getItem("loss_count") || 0)
  const [winCount, setWinCount] = useState(localStorage.getItem("win_count") || 0)
  const [capital, setCapital] = useState(localStorage.getItem("capital") || 0)
  const [gameCount, setGameCount] = useState(localStorage.getItem("game_count") || 0)
  const [loading, setLoading] = useState(false)
  const [overTwoFiveStake, setOverTwoFiveStake] = useState()
  const [overTwoFiveOdd, setOverTwoFiveOdd] = useState()
  const [minPercentage, setMinPercentage] = useState(parseInt(0))
  const [target_percentage, setTargetPercenate] = useState(0)
  const [errorMessage, setErrorMessage] = useState("")
  const [stopLost, setStopLost] = useState(localStorage.getItem("stop_lost") || 0)
  const [stretch, setStretch] = useState(localStorage.getItem("stretch") || 0)
  const [splits, setSplits] = useState(localStorage.getItem("splits") || 0)
  const [show, setShow] = useState(true)
  const [guideModal, setGuideModal] = useState(false)
  const [showMessage, setShowMessage] = useState(false)






  const calculatePercentage = useMemo(() => {
    const targetP = (parseInt(gameTarget) / parseInt(capital)) * 100;
    const percentage = (1.5 / 100) * parseInt(capital);
    return { targetP, percentage };
  }, [gameTarget, capital]);

  useEffect(() => {
    setMinPercentage(calculatePercentage.targetP);
    setTargetPercenate(calculatePercentage.percentage);
  }, [calculatePercentage]);

  let stopPercentage = (stopLost / 100) * capital;

  useEffect(() => {
    setShowMessage(totalLosses > stopPercentage);
  }, [totalLosses, stopPercentage]);


  const subStopLost = () => {
      let splitStopLoss = totalLosses / splits;
      localStorage.setItem("splitted_lost", totalLosses);
      localStorage.setItem("totalLosses", splitStopLoss);
      setTotalLoses(splitStopLoss);
  };


  useEffect(() => {
    if (lossCount == stretch) {
      subStopLost();
    }
  }, [lossCount]);


  const bringBackTheSplittedAmount = () => {
    if(totalLosses == 0){
      if(parseInt(localStorage.getItem("splitted_lost") > 0)){
        let getSplittedAmount = parseInt(localStorage.getItem("splitted_lost"))
        let newStopLost = getSplittedAmount / splits 
        let newSplitAmount = getSplittedAmount - newStopLost
        localStorage.setItem("totalLosses", newStopLost)
        setTotalLoses(newStopLost)
        localStorage.setItem("splitted_lost", newSplitAmount)
        localStorage.setItem("loss_count", 0)
      
      }
    }
  }

  useEffect(() => {
    if (totalLosses == 0) {
      bringBackTheSplittedAmount();
    }
  }, [totalLosses])






  const calculateOverTwoFiveStake = () => {
    let newStake  = (parseInt(localStorage.getItem('totalLosses')) ) + parseInt(localStorage.getItem('game_target'))
    let finalStakeAmount = newStake / (overTwoFiveOdd - 1)
    setOverTwoFiveStake(finalStakeAmount)
    localStorage.setItem("overTwoFiveStake", finalStakeAmount)
  }


const updateGameDetails = () => {
    localStorage.setItem('game_status', 'Ended');
    localStorage.setItem('overTwoFiveStake', 0);
}

    
  const [modal, setModal] = useState(false);

  const toggle = () => {
      setModal(!modal)
  }


  const loadGameStatus = () => {
    setTotalProfit(localStorage.getItem("profit"))
    setTotalLoses(localStorage.getItem("totalLosses"))
    setCapital(localStorage.getItem("capital"))
    setGameTarget(localStorage.getItem("game_target"))
    setGameCount(localStorage.getItem("game_count"))
    setLossCount(localStorage.getItem("loss_count"))
    setWinCount(localStorage.getItem("win_count"))
    setSplits(localStorage.getItem("splits"))
    setStopLost(localStorage.getItem("stop_lost"))
    setStretch(localStorage.getItem("stretch"))
  }

  const handleSubmit = () => {
    setLoading(true)  

    try {
         if(minPercentage > 3){
            setErrorMessage(`Sorry your maximum wining target for &#8358;${capital} should be  &#8358;${target_percentage}`)
         }else{
            localStorage.setItem('win_count', 0)
            localStorage.setItem('game_count', 0)
            localStorage.setItem("game_target", gameTarget)
            localStorage.setItem("capital", capital)
            localStorage.setItem("loss_count", 0)
            localStorage.setItem("stop_lost", stopLost)
            localStorage.setItem("stretch", stretch)
            localStorage.setItem("splits", splits)
            toggle()
            loadGameStatus()
            toast.success("Session configured successfully")
            setLoading(false)
         }
    } catch (error) {
      setLoading(false)
    }
    
  }

  const stakeNow = () => {
    setLoading(true)
    calculateOverTwoFiveStake()
    localStorage.setItem('game_status', "Playing")
    localStorage.setItem('overTwoFiveOdd', overTwoFiveOdd)
 
    setLoading(false)
    toast.success("Stake calculated successfully")
  
  }



  const lossClick = () => {
    const totalLost = parseInt(localStorage.getItem('totalLosses')) || 0;
    const amountStaked = parseInt(localStorage.getItem('overTwoFiveStake')) || 0
    const updatedLosses = totalLost + amountStaked;

    const game_counts = parseInt(localStorage.getItem('game_count')) || 0;
    const loss_count = parseInt(localStorage.getItem('loss_count')) || 0;
    const updatedGameCount = game_counts + 1
    const updatedLossCount =  loss_count + 1;

 
    localStorage.setItem('totalLosses', updatedLosses);
    localStorage.setItem('game_count', updatedGameCount);
    localStorage.setItem('loss_count', updatedLossCount);

    updateGameDetails()
    setLoading(false);
    toast.success('Oops try again');
    loadGameStatus();
  }


  // Win is 3
  const winClick = () => { 
    setLoading(true);

    const gameTarget = parseInt(localStorage.getItem('game_target')) || 0;
    const profit = parseInt(localStorage.getItem('profit')) || 0;
    const updatedProfit = profit + (gameTarget);

    const winCounts = parseInt(localStorage.getItem('win_count')) || 0;
    const game_counts = parseInt(localStorage.getItem('game_count')) || 0;

    const updateWinCount = winCounts + 1
    const updatedGameCount = game_counts + 1
  
    localStorage.setItem('profit', updatedProfit);
    localStorage.setItem('totalLosses', 0);
    localStorage.setItem('win_count', updateWinCount);
    localStorage.setItem('game_count', updatedGameCount);
    localStorage.setItem("loss_count", 0)
    updateGameDetails()
    setLoading(false);
    toast.success('Congratulations boss');
    loadGameStatus();
  } 

  


  const resetData = () => {
    localStorage.setItem("profit", 0)
    localStorage.setItem("totalLosses", 0)
    localStorage.setItem("game_target", 0)
    localStorage.setItem("overTwoFiveOdd", 0)
    localStorage.setItem("win_count", 0)
    localStorage.setItem("loss_count", 0)
    localStorage.setItem("game_count", 0)
    localStorage.setItem("capital", 0)
    localStorage.setItem("splitted_lost", 0)
    localStorage.setItem("splits", 0)
    localStorage.setItem("stop_lost", 0)
    localStorage.setItem("stretch", 0)
    loadGameStatus()
    updateGameDetails()
    toast.success("System reset successfully")
  }

  const toogle = () => {
      setShow(!show)
  }

  const guideToggle = () => {
    setGuideModal(!guideModal)
  }
  

  
  


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Suretrada | Over 2.5 calculator </title>
        </MetaTags>
    
        <>
         <Container >
          <Row>
             <span style={{ marginLeft: "20px", fontWeight: "bold", marginBottom: "20px"}} onClick={() => toogle()}>{show ? "Hide System Details" : "Show System Details"}</span>
              { show ? (
                <>
                   <Col md="4"  sm="12" xl="4" style={{ backgroundColor: "white",borderRadius: "10px"}}>
                        <div style={{ borderRadius: "10px", border: "1px solid black", padding: "10px", marginTop:"20px"}}>
                          <span>Accommulated Lost</span> <span style={{ float: "right"}}>{<NumberFormat  value={Math.round(totalLosses)}  className="foo" displayType={'text'} thousandSeparator={true} renderText={(value, props) => <div {...props}>&#8358;{value}</div>}   /> }</span> <br />
                          <span>Profit</span> <span style={{ float: "right"}}>{<NumberFormat  value={Math.round(totalProfit)}  className="foo" displayType={'text'} thousandSeparator={true} renderText={(value, props) => <div {...props}>&#8358;{value}</div>}   /> }</span> <br />
                          <span>Capital</span> <span style={{ float: "right"}}>{<NumberFormat  value={Math.round(capital)}  className="foo" displayType={'text'} thousandSeparator={true} renderText={(value, props) => <div {...props}>&#8358;{value}</div>}   /> }</span> <br />
                          <span>Game target</span> <span style={{ float: "right"}}>{<NumberFormat  value={Math.round(gameTarget)}  className="foo" displayType={'text'} thousandSeparator={true} renderText={(value, props) => <div {...props}>&#8358;{value}</div>}   /> }</span> <br />
                          <hr />
                          <span>Total Game Played</span> <span style={{ float: "right"}}>{gameCount}</span> <br />
                          <span>Game Lost</span> <span style={{ float: "right", color: "red"}}>{lossCount}</span> <br />
                          <span>Game Won</span> <span style={{ float: "right", color: "green"}}>{winCount}</span> <br />
                          <span>Stop lost</span> <span style={{ float: "right", color: "gray"}}>{stopLost} % of capital</span> <br />
                          <span>Split on</span> <span style={{ float: "right", color: "gray"}}>{stretch} Stretches</span> <br />
                          <span>No of splits</span> <span style={{ float: "right", color: "gray"}}>{splits}</span> <br />
                        </div>

                        {showMessage && 
                            <>
                                  <hr />
                                  <Alert className="info" style={{ backgroundColor: "#f57971", color: "white"}}>
                                      <p>
                                      
                                      You have lost {stopLost}% of your capital and we advice you apply a stop lost here and reset the system.
                                      </p>
                                  </Alert>
                            </>
                         
                        }
                   
                    </Col>
                </>
              ) : null}
             

             <Col md="6" sm="12" xl="6">
             <div className="text-sm-end" style={{ marginTop: "10px"}}>

                <Button
                    color="default"
                    className="font-16 btn-block btn btn-default"
                    onClick={() => {setModal(true)}}
                    style={{
                      border: "1px solid #5719D0"
                    }}
                  >
                   
                    Setup
                  </Button>

                  <Button
                    color="default"
                    className="font-16 btn-block btn btn-default"
                    onClick={() => {resetData()}}
                    style={{
                      border: "1px solid #5719D0",
                     
                      
                    }}
                  >
                 
                    Reset System
                  </Button>

                  <Button
                    color="default"
                    className="font-16 btn-block btn btn-default"
                    onClick={() => {setGuideModal(true)}}
                    style={{
                      border: "1px solid #5719D0",
                      
                    }}
                  >
               
                    Guidelines
                  </Button>
                </div>
                <hr style={{ backgroundColor: "#5719D0"}} />

                <GeneralCard title="Amount to stake on over 2.5" details={
                   <NumberFormat
                   value={Math.round(overTwoFiveStake)}
                   className="foo"
                   displayType={'text'}
                   thousandSeparator={true}
                   renderText={(value, props) => <div {...props}>&#8358;{value}</div>}
                   /> 
                } />

                <hr />

                {parseInt(localStorage.getItem("game_target")) > 0 && (
                  <>
                   
                      <Row>
                        <Col md="12">
                        <h2>Enter Odds</h2>
                         {localStorage.getItem("game_status") === "Playing" ? (
                          <>
                           <p>A game is going on now.</p>
                          </>
                         ) : (
                          <>
                         <div className="mb-3">
                          <Row>
                           
                            <Col md="12">
                              <Label className="form-label">Over 2.5 Odd</Label>
                                <Input
                                  name="underOdd"
                                  type="number"
                                  onChange={(e) => setOverTwoFiveOdd(e.target.value)}
                                />
                            </Col>
                          </Row>


                             <Button color="default" disabled={overTwoFiveOdd === "" || overTwoFiveOdd == 0  ? true : false} className="font-16 btn-block btn btn-default" onClick={() => {stakeNow()} } style={{ border: "1px solid gray", marginTop: "10px"}} >
                                 Calculate
                              </Button>
                            </div>
                          </>
                         )}
                      
                        </Col>
                        <Col md="12" sm="12" xl="">
                            <h3>Current Game</h3>
                           {localStorage.getItem('game_status') === "Playing" ? (
                            <>
                           <span>Game Status</span>  
                           <span style={{ float: "right"}}>{localStorage.getItem("game_status")}</span> <br />

                         

                          <span>Over 2.5 Stake</span>  
                           <span style={{ float: "right"}}>
                           <NumberFormat
                            value={Math.round(localStorage.getItem("overTwoFiveStake"))}
                            className="foo"
                            displayType={'text'}
                            thousandSeparator={true}
                            renderText={(value, props) => <div {...props}>&#8358;{value}</div>}
                            /> 
                            
                          </span>  <br />

                          
                           <span>Over 2.5 Odd</span>  
                           <span style={{ float: "right"}}>{localStorage.getItem("overTwoFiveOdd")}</span>  <br />

                        

                           
                           <span>Result(No of Goals)</span>  
                           <span style={{ float: "right"}}>
                              <Button color="default" className="font-16 btn-block btn btn-default" onClick={() => {lossClick()}} style={{ border: "1px solid gray", marginTop: "10px", marginLeft: "10px"}} >
                                 Loss
                              </Button>

                              <Button color="default" className="font-16 btn-block btn btn-default" onClick={() => {winClick()}} style={{ border: "1px solid gray", marginTop: "10px", marginLeft: "10px"}} >
                                 Win
                              </Button>

                            

                           </span> 
                            </>
                           ) : (
                            <p>No game is going on.</p>
                           )}
                        </Col>
                      </Row>
                    
           
                  </>
                 )}

      
             </Col>

             <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                    return false;
                                }}
                                >
                                <Row form>
                                <Col xs={12}>
                                    <Row>
                                       <Col md="6">
                                          <div className="mb-3">
                                            <Label className="form-label">Capital</Label>
                                            <Input
                                              name="capital"
                                              type="text"
                                              onChange={(e) => setCapital(e.target.value)}
                                              />
                                           </div>
                                       </Col>
                                       <Col md="6">
                                          <div className="mb-3">
                                            <Label className="form-label">Game Target</Label>
                                              <Input
                                                  name="game_target"
                                                  type="text"
                                                  onChange={(e) => setGameTarget(e.target.value)}
                                                />
                                                    <span style={{ color: "red"}}>{errorMessage}</span>
                                                        {minPercentage > 1.5 && 
                                                         <span style={{ color: "red"}}>Sorry your maximum wining target for
                                                         &#8358;{capital} should be  &#8358;{target_percentage}
                                                     </span>}
                                           </div>
                                       </Col>
                                    </Row>

                                    <Row>
                                       <Col md="12">
                                          <div className="mb-3">
                                            <Label className="form-label">Stop Lost</Label>
                                            <select className="form-control"        onChange={(e) => setStopLost(e.target.value)}>
                                                  <option value={5}>5% of capital</option>
                                                  <option value={10}>10% of capital</option>
                                                  <option value={15}>15% of capital</option>
                                                  <option value={20}>20% of capital</option>
                                            </select>
                                          
                                           </div>
                                       </Col>
                                       <Col md="6">
                                          <div className="mb-3">
                                            <Label className="form-label">Split After</Label>
                                            <select className="form-control"  onChange={(e) => setStretch(e.target.value)}>
                                                  <option value={4}>4 Stretches</option>
                                                  <option value={5}>5 Stretches</option>
                                                  <option value={6}>6 Stretches</option>
                                                  <option value={7}>7 Stretches</option>
                                            </select>
                                              
                                           </div>
                                       </Col>

                                       <Col md="6">
                                          <div className="mb-3">
                                            <Label className="form-label">No of splits</Label>
                                            <select className="form-control"    onChange={(e) => setSplits(e.target.value)}>
                                                  <option value={2}>2</option>
                                                  <option value={4}>4</option>
                                                  <option value={6}>6</option>
                                                  <option value={8}>8</option>
                                            </select>
                                             
                                           </div>
                                       </Col>
                                    </Row>
  
                                 </Col>
                                </Row>
                                <Row>
                                <Col>
                                    <div className="text-end">

                                    <button
                                        type="submit"
                                        disabled={minPercentage > 3 || gameTarget == 0 || capital == 0 ? true : false  }
                                        className="btn btn-default"
                                        style={{ backgroundColor: "#5719D0", color: "#fff"}}
                                    >
                                        Save Now
                                    </button>
                                    </div>
                                </Col>
                                </Row>
                            </Form>
                            </ModalBody>
                        </Modal>



                     <Modal isOpen={guideModal} toggle={guideToggle}>
                        <ModalHeader toggle={guideToggle} tag="h4">Info & Guidelines </ModalHeader>
                            <ModalBody>
                               <p>
                                  <span style={{ fontWeight: "bold"}}>Accommulated Lost:</span><br />
                                  This is the total amount you have lost in your betting account in a session.(Note a session start when you start following
                                  a team to play over 2.5, the session ends when you hit a win.)
                                </p>

                                <p>
                                  <span style={{ fontWeight: "bold"}}>Capital:</span><br />
                                  This is the supposed amount you have to risk in your betting/bookmaker account(Note we dont have access to your betting account hence we cant tell how much you have there unless you input the value correctly on our system during setup.)
                                </p>

                                <p>
                                  <span style={{ fontWeight: "bold"}}>Game Target:</span><br />
                                  This is the supposed amount you have to risk in your betting/bookmaker account(Note we dont have access to your betting account hence we cant tell how much you have there unless you input the value correctly on our system during setup.)
                                </p>
                              <div style={{ borderRadius: "10px", border: "1px solid black", padding: "10px", marginTop:"20px"}}>
                                <p>
                                <span style={{ fontWeight: "bold"}}>Extra Tips for Productive Trading</span>  <br />
                                1. If the system notifies you to declare stop loss, please do so and reset your system. Continue trading, preferably in the middle of the second section/segment. <br />
                                2. Ensure you input correct data into the system.
                                3. Avoid chasing a loss; instead, accept it and start anew. Chasing losses can lead to even greater losses.  <br />
                                4. Divide each league into 4 segments/sections: 1 - 10, 11 - 20, 21 - 30, 31 - 38. Aim for 2 wins in each segment/section.  <br />
                                5. Consider declaring a stop loss if you haven&apos;t achieved a win for 5, 6, or 7 consecutive weeks, depending on your capital.  <br />
                                6. Setting a higher target may yield more profit but requires more capital over a stretch. Conversely, a lower target yields less profit but consumes less capital over time.  <br />
                                7. For improved productivity, use two devices: one for calculations and the other for the betting app.  <br />
                                8. Ensure your network connection is stable.  <br />
                                9. Stay focused and avoid distractions while trading.  <br />
                                10. Always fund your betting wallet before initiating trades.
                                </p>
                              </div>
                            </ModalBody>
                     </Modal>
      
          </Row>
        </Container>
        </>
       
       
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  userDetails: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default Dashboard;

import Iconfiy from 'pages/Utility/Iconfiy';
import React, { useRef, useEffect, useState } from 'react'
import { MetaTags } from 'react-meta-tags';
import { Container, Alert } from 'reactstrap';
import Notifications from 'pages/Utility/Notifications';
import PropTypes from "prop-types";
import axios from "axios";
import Loader from 'pages/Utility/Loader'
import Iconify from '../Utility/Iconfiy'
import AuthCode from "react-auth-code-input"


const API_URL = process.env.REACT_APP_BASE_URL;


const axiosApi = axios.create({
  baseURL: API_URL,
});









const EmailVerify = props => {
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [tokenError, setTokenError] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [otp, setOtp] = useState("")
  const inputRefs = Array.from({ length: 4 }, () => useRef(null));
  const [loading, setLoading] = useState(false)



  useEffect(() => {
    if (localStorage.getItem("token")) {
        const obj = JSON.parse(localStorage.getItem("profile"))
        setEmail(obj.data.email)
        setPhone(obj.data.phone_number)
    
    }
  }, [])


  const handleChange = (code) => {
    setOtp(code)
}

const resendCode = () => {
  setLoading(true)
  const data = {
      email: email,
      phone_number: phone,
      otpType: "PHONE-VERIFICATION",

  }
  axiosApi.post(`${API_URL}/api/v1/otp/request`, data).then((res) => {
      setLoading(false);
      setSuccessMessage("Code has been sent successfully")
   }).catch((err) => {
      setTokenError(err.response.message)
      setLoading(false);
   })
}


  const phone_number = phone?.substr(0, 3) + "******" + phone?.substr(-2, 2)


  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      otp: otp,
      otpType: "PHONE-VERIFICATION",
      email: email
  }
  setLoading(true);
   axiosApi.post(`${API_URL}/api/v1/otp/verify`, data).then((res) => {
          localStorage.setItem("profile", JSON.stringify(res));
          // props.history.push("/create-pin");   
          props.history.push("/dashboard")
   }).catch((err) => {
       setTokenError(err.response.message)
      setLoading(false);
   })
  }
  

  return (
    <React.Fragment>
      <div className='all-container'>
        <MetaTags>
          <title>Suretrada | Verify your account</title>
        </MetaTags>

       

        <Container>
        {loading && <Loader/>}
          <div className='main-contentyu container-fluid d-flex justify-content-center'>
            <div className="major-width verify">
              <div className='close-button'>
                <Iconfiy icon="ion:close" width="15px"/>
              </div>
              <div className='text-center migi'>
                <h2>Verify Your Phone Number</h2>
                <p>Pleas enter the verification code sent to your number ({phone_number})</p>
                {tokenError ? <Alert color="danger">{tokenError}</Alert> : null}
                {successMessage ? <Alert color="success">{successMessage}</Alert> : null}
              </div>
              <div className='verification-container'>
              <AuthCode
                characters={4}
            
                className="form-control form-control-lg text-center"
                inputStyle={{
                  width: "50px",
                  height: "calc(1.5em + 1rem + 2px)",
                  padding: ".5rem 1rem",
                  borderRadius: "8px",
                  fontSize: "1.01562rem",
                  textAlign: "center",
                  marginRight: "15px",
                  border: "1px solid #ced4da",
                  textTransform: "uppercase",
                  borderRadius: ".4rem"
                }}
                onChange={handleChange}
              />
              </div>
              <div className='verify-send' onClick={(e) => { resendCode()}} >
              <span>Didn&apos;t get a code?</span> <a>Resend</a>
              </div>
              
              <button className='btn btn-primary' onClick={(e) =>  handleSubmit(e) }>Verify</button>

            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EmailVerify

EmailVerify.propTypes = {
  history: PropTypes.object,
};

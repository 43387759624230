import React from 'react'
import PropTypes from 'prop-types';
import Iconfiy from 'pages/Utility/Iconfiy'
import SpoowealthIcon from "../../assets/images/spoowealth.png"





const TransactionCom = ({type, amount, status, date, onclick}) => {
  return (
    <div className='temp-contain d-flex flex-row align-items-center' onClick={onclick}>
      <div className='spooIcon'>
           {type === "WITHDRAWAL" ? (<><Iconfiy icon="solar:arrow-up-outline" angle="225" width="20px" color="red"  /></>) :
            type === "FUNDING" ? (<> <Iconfiy icon="ic:round-plus" width="13px" color="green"/> </>) :
            type === "BUY_GAME" ? (<> <Iconfiy icon="icon-park-outline:game-three" width="13px" color="green"/> </>) :
            type === "SPOOWEALTH" ? (<> <img src={SpoowealthIcon} /> </>)
            : null
          }
      </div>

      <div style={{ flexGrow: "1" }}>
        <h4>{type === "SIGNUP_BONUS" ? "Welcome bonus" :
         type === "MC_DASAINT_BONUS" ? "Mc Dasaint bonus" : 
         type === "SUBSCRIPTION" ? "Subscription" : 
         type === "BUY_GAME" ? "Game purchase" : 
         type === "JOIN_AUTO_TRADA" ? "Fund auto-trada" : 
         type === "FUNDING" ? "Top-up" : null}</h4>
        <p>{date}</p>
      </div>

      <div>
         {type === "WITHDRAWAL" ? (<h4>- ₦{amount}</h4>) : type === "FUNDING" ? (<h4> +₦{amount}</h4>) : type === "SPOOWEALTH" ? (<h4>- ₦{amount}</h4>) : <h4>₦{amount}</h4>}
      
         <p style={status === "SUCCESS" ? {color: "green"} : status === "PENDING" ? {color: "orange"} : null}>{status}</p>
      </div>
    </div>

  )
}

export default TransactionCom


TransactionCom.propTypes = {
    type: PropTypes.any,
    amount: PropTypes.any,
    status: PropTypes.any,
    date: PropTypes.any,
    onclick: PropTypes.any
}


  
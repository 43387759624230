import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useMemo } from "react";
import MetaTags from "react-meta-tags";
import { ToastContainer, toast } from 'react-toastify';
import ReportCard from "components/Common/ReportCard"
import 'moment-timezone';
import NumberFormat from 'react-number-format';
import Loader from "pages/Utility/Loader";

import GeneralCard from "../../components/Common/GeneralCard"





import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Label,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Button
} from "reactstrap";


const API_URL = process.env.REACT_APP_BASE_URL


const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use((req) => {
  if(localStorage.getItem('profile')){
      req.headers.Authorization = `Bearer ${ JSON.parse(localStorage.getItem('profile')).token}`,
      req.headers.Accept = 'application/json'
  }
  return req;
});



const Dashboard = props => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")))
  const [totalLosses, setTotalLoses ] = useState(localStorage.getItem('totalLost') || 0)
  const [totalProfit, setTotalProfit] = useState(localStorage.getItem('gameProfit') || 0)
  const [gameTarget, setGameTarget] = useState(localStorage.getItem('gameTarget') || 0)
  const [lossCount, setLossCount] = useState(localStorage.getItem("lossCount") || 0)
  const [winCount, setWinCount] = useState(localStorage.getItem("winCount") || 0)
  const [option, setOption] = useState(localStorage.getItem("option") || "")
  const [odd, setOdd] = useState(localStorage.getItem("odd") || 0)
  const [stake, setStake] = useState(localStorage.getItem("stake") || 0)
  const [gameCount, setGameCount] = useState(localStorage.getItem("gameCount") || 0)
  const [show, setShow] = useState(true)









  const calculateStake = () => {
    let newStake  = (parseInt(localStorage.getItem('totalLost')) ) + parseInt(localStorage.getItem('gameTarget'))
    let finalStakeAmount = newStake / (odd - 1)
    setStake(finalStakeAmount)
    localStorage.setItem("stake", finalStakeAmount)
  }


const updateGameDetails = () => {
    localStorage.setItem('gameStatus', 'Ended');
    localStorage.setItem('stake', 0);
}

    
  const [modal, setModal] = useState(false);

  const toggle = () => {
      setModal(!modal)
  }

  const handleSubmit = () => {
    try {
            localStorage.setItem('winCount', 0)
            localStorage.setItem('gameCount', 0)
            localStorage.setItem("gameTarget", gameTarget)
            localStorage.setItem("lossCount", 0)
            toggle()
            loadGameStatus()
            toast.success("Session configured successfully")
            setLoading(false)
    } catch (error) {
  
    }
    
  }

  const stakeNow = () => {
    calculateStake()
    localStorage.setItem('gameStatus', "Playing")
    localStorage.setItem('odd', odd)
    localStorage.setItem("option", option)
    toast.success("Stake calculated successfully")
  
  }



  const lossClick = () => {
    const totalLost = parseInt(localStorage.getItem('totalLost')) || 0;
    const amountStaked = parseInt(localStorage.getItem('stake')) || 0
    const updatedLosses = totalLost + amountStaked;

    const game_counts = parseInt(localStorage.getItem('gameCount')) || 0;
    const loss_count = parseInt(localStorage.getItem('lossCount')) || 0;
    const updatedGameCount = game_counts + 1
    const updatedLossCount =  loss_count + 1;

 
    localStorage.setItem('totalLost', updatedLosses);
    localStorage.setItem('gameCount', updatedGameCount);
    localStorage.setItem('lossCount', updatedLossCount);

    updateGameDetails()
    toast.success('Oops try again');
    loadGameStatus();
  }


  // Win is 3
  const winClick = () => { 
  
    const gameTarget = parseInt(localStorage.getItem('gameTarget')) || 0;
    const profit = parseInt(localStorage.getItem('gameProfit')) || 0;
    const updatedProfit = profit + (gameTarget);

    const winCounts = parseInt(localStorage.getItem('winCount')) || 0;
    const game_counts = parseInt(localStorage.getItem('gameCount')) || 0;

    const updateWinCount = winCounts + 1
    const updatedGameCount = game_counts + 1
  
    localStorage.setItem('gameProfit', updatedProfit);
    localStorage.setItem('totalLost', 0);
    localStorage.setItem('winCount', updateWinCount);
    localStorage.setItem('gameCount', updatedGameCount);
    localStorage.setItem("lossCount", 0)
    updateGameDetails()
    toast.success('Congratulations boss');
    loadGameStatus();
  } 

  

  const loadGameStatus = () => {
    setTotalProfit(localStorage.getItem("gameProfit"))
    setTotalLoses(localStorage.getItem("totalLost"))
    setGameTarget(localStorage.getItem("gameTarget"))
    setGameCount(localStorage.getItem("gameCount"))
    setLossCount(localStorage.getItem("lossCount"))
    setWinCount(localStorage.getItem("winCount"))
  }

  const resetData = () => {
    localStorage.setItem("gameProfit", 0)
    localStorage.setItem("totalLost", 0)
    localStorage.setItem("gameTarget", 0)
    localStorage.setItem("odd", 0)
    localStorage.setItem("winCount", 0)
    localStorage.setItem("lossCount", 0)
    localStorage.setItem("gameCount", 0)
    localStorage.setItem("stake", 0)
    loadGameStatus()
    updateGameDetails()
    toast.success("System reset successfully")
  }

  const toogle = () => {
      setShow(!show)
  }
  

  
  


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Suretrada | Stake Calculator</title>
        </MetaTags>

       
         <>
          <Container>
          <Row>

            
             <span style={{ marginLeft: "20px", fontWeight: "bold", marginBottom: "20px"}} onClick={() => toogle()}>{show ? "Hide System Details" : "Show System Details"}</span>
              { show && (
                <>
                   <Col md="4" style={{ backgroundColor: "white", borderRadius: "20px"}}>
                        <div style={{ borderRadius: "10px", border: "1px solid black", padding: "10px"}}>
                         <span>Accommulated Lost</span> <span style={{ float: "right"}}>{<NumberFormat  value={Math.round(totalLosses)}  className="foo" displayType={'text'} thousandSeparator={true} renderText={(value, props) => <div {...props}>&#8358;{value}</div>}   /> }</span> <br />
                          <span>Profit</span> <span style={{ float: "right"}}>{<NumberFormat  value={Math.round(totalProfit)}  className="foo" displayType={'text'} thousandSeparator={true} renderText={(value, props) => <div {...props}>&#8358;{value}</div>}   /> }</span> <br />
                          <span>Game target</span> <span style={{ float: "right"}}>{<NumberFormat  value={Math.round(gameTarget)}  className="foo" displayType={'text'} thousandSeparator={true} renderText={(value, props) => <div {...props}>&#8358;{value}</div>}   /> }</span> <br />
                          <hr />
                          <span>Total Game Played</span> <span style={{ float: "right"}}>{gameCount}</span> <br />
                          <span>Game Lost</span> <span style={{ float: "right", color: "red"}}>{lossCount}</span> <br />
                          <span>Game Won</span> <span style={{ float: "right", color: "green"}}>{winCount}</span> <br />
                        </div>
                    </Col>
                </>
              )}
             

             <Col md="6" style={{ marginTop: "20px"}}>
             <div className="text-sm-end">

                <Button
                    color="default"
                    className="font-16 btn-block btn btn-default"
                    onClick={() => {setModal(true)}}
                    style={{
                      border: "1px solid #5719D0"
                    }}
                  >
                    <i className="mdi mdi-export me-1" />
                    Start Session
                  </Button>

                  <Button
                    color="default"
                    className="font-16 btn-block btn btn-default"
                    onClick={() => {resetData()}}
                    style={{
                      border: "1px solid #5719D0",
                      marginLeft: "20px"
                      
                    }}
                  >
                    <i className="mdi mdi-export me-1" />
                    Reset System
                  </Button>
                </div>
                <hr style={{ backgroundColor: "#5719D0"}} />

                <GeneralCard title={`Amount to stake on ${option}`} details={
                   <NumberFormat
                   value={Math.round(stake)}
                   className="foo"
                   displayType={'text'}
                   thousandSeparator={true}
                   renderText={(value, props) => <div {...props}>&#8358;{value}</div>}
                   /> 
                } />

                <hr />

                {parseInt(localStorage.getItem("gameTarget")) > 0 && (
                  <>
                     <Card style={{ padding: "20px"}}>
                      <Row>
                        <Col md="12">
                        <h2>Enter Game Details</h2>
                         {localStorage.getItem("gameStatus") === "Playing" ? (
                          <>
                           <p>A game is going on now.</p>
                          </>
                         ) : (
                          <>
                         <div className="mb-3">
                          <Row>
                            <Col md="6">
                              <Label className="form-label">Option</Label>
                                <Input
                                  name="option"
                                  type="text"
                                  onChange={(e) => setOption(e.target.value)}
                                />
                            </Col>

                            <Col md="6">
                              <Label className="form-label">Odd</Label>
                                <Input
                                  name="odd"
                                  type="text"
                                  onChange={(e) => setOdd(e.target.value)}
                                />
                            </Col>
                          </Row>


                             <Button color="default" className="font-16 btn-block btn btn-default" onClick={() => {stakeNow()} } style={{ border: "1px solid gray", marginTop: "10px"}} >
                                 Calculate
                              </Button>
                            </div>
                          </>
                         )}
                      
                        </Col>
                        <Col md="12">
                            <h3>Current Game</h3>
                           {localStorage.getItem('gameStatus') === "Playing" ? (
                            <>
                           <span>Game Status</span>  
                           <span style={{ float: "right"}}>{localStorage.getItem("gameStatus")}</span> <br />

                         

                          <span>{option} stake</span>  
                           <span style={{ float: "right"}}>
                           <NumberFormat
                            value={Math.round(localStorage.getItem("stake"))}
                            className="foo"
                            displayType={'text'}
                            thousandSeparator={true}
                            renderText={(value, props) => <div {...props}>&#8358;{value}</div>}
                            /> 
                            
                          </span>  <br />

                          
                           <span>{option} Odd</span>  
                           <span style={{ float: "right"}}>{localStorage.getItem("odd")}</span>  <br />

                        

                           
                           <span>Result</span>  
                           <span style={{ float: "right"}}>
                              <Button color="default" className="font-16 btn-block btn btn-default" onClick={() => {lossClick()}} style={{ border: "1px solid gray", marginTop: "10px", marginLeft: "10px"}} >
                                 Loss
                              </Button>

                              <Button color="default" className="font-16 btn-block btn btn-default" onClick={() => {winClick()}} style={{ border: "1px solid gray", marginTop: "10px", marginLeft: "10px"}} >
                                 Win
                              </Button>

                            

                           </span> 
                            </>
                           ) : (
                            <p>No game is going on.</p>
                           )}
                        
                         
                        </Col>
                      </Row>
                    
                    </Card>
                  </>
                 )}

      
             </Col>

             <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                    return false;
                                }}
                                >
                                <Row form>
                                <Col xs={12}>
            
                                    
                                    <div className="mb-3">
                                        <Label className="form-label">Game Target</Label>
                                        <Input
                                            name="game_target"
                                            type="text"
                                            onChange={(e) => setGameTarget(e.target.value)}
                                    />
                                   
                                
                                       
                                    </div>
                                    
                                </Col>
                                </Row>
                                <Row>
                                <Col>
                                    <div className="text-end">

                                    <button
                                        type="submit"
                                       
                                        className="btn btn-default"
                                        style={{ backgroundColor: "#5719D0", color: "#fff"}}
                                    >
                                        Save Now
                                    </button>
                                    </div>
                                </Col>
                                </Row>
                            </Form>
                            </ModalBody>
                        </Modal>
      
          </Row>
        </Container>
         </>

       
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  userDetails: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default Dashboard;

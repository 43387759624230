/* Wallet */
export const GET_WALLET = "GET_WALLET"
export const GET_WALLET_SUCCESS = "GET_WALLET_SUCCESS"
export const GET_WALLET_FAIL = "GET_WALLET_FAIL"

/* ORDERS */
export const GET_CRYPTO_ORDERS = "GET_CRYPTO_ORDERS"
export const GET_CRYPTO_ORDERS_SUCCESS = "GET_CRYPTO_ORDERS_SUCCESS"
export const GET_CRYPTO_ORDERS_FAIL = "GET_CRYPTO_ORDERS_FAIL"


/* update wallet */
export const UPDATE_WALLET = "UPDATE_WALLET"
export const UPDATE_WALLET_SUCCESS = "UPDATE_WALLET_SUCCESS"
export const UPDATE_WALLET_FAIL = "UPDATE_WALLET_FAIL"

import React from 'react'
import PropTypes from 'prop-types';
import Iconfiy from 'pages/Utility/Iconfiy'
import sporty from "assets/images/images.png"
import bet9ja from "assets/images/bet9.png"
import OneXBet from "assets/images/onex.png"




const GameCard = ({title, amount, status, punter, onclick, platform, timestamp, rating}) => {
  return (
    <div className='temp-contain d-flex flex-row align-items-center' onClick={onclick}>
      <div className='spooIcon'>
          <img alt=''  src={platform === "sportybet" ? sporty 
            : platform === "bet9ja" ? bet9ja 
            : platform === "1xbet" ? OneXBet 
            : null} />  
      </div>

      <div style={{ flexGrow: "2" }}>
        <h4>{title}</h4>
        <p style={{  display: "flex", flexDirection: "row", marginBottom: "3px"}}>By {punter} <span style={{ fontWeight: "bolder", paddingLeft: "5px"}}>(ratings: {rating} {rating > 50 ? <Iconfiy icon="ph:arrow-up" color="green" height="20" width="15" /> :  <Iconfiy icon="ph:arrow-down" color="red" height="20" width="15" /> } )  </span> </p>
        <span style={{ backgroundColor: "#5719D0", color: "white", borderRadius: "5px", padding: "5px", marginTop: "5px"}}>{timestamp} </span> | <span style={{ backgroundColor: "black", color: "white", borderRadius: "5px", padding: "5px", marginTop: "5px"}}>{amount == 0 ? "Free" : "₦" + amount}</span>
      </div>

  
    </div>

  )
}

export default GameCard


GameCard.propTypes = {
    title: PropTypes.any,
    amount: PropTypes.any,
    status: PropTypes.any,
    date: PropTypes.any,
    punter: PropTypes.any,
    onclick: PropTypes.any,
    platform: PropTypes.any,
    rating: PropTypes.any,
    timestamp: PropTypes.any
}


  
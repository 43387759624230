import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useMemo } from "react";
import MetaTags from "react-meta-tags";
import { ToastContainer, toast } from 'react-toastify';
import ReportCard from "components/Common/ReportCard"
import 'moment-timezone';
import NumberFormat from 'react-number-format';
import Loader from "pages/Utility/Loader";

import GeneralCard from "../../components/Common/GeneralCard"





import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Label,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Button
} from "reactstrap";


const API_URL = process.env.REACT_APP_BASE_URL


const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use((req) => {
  if(localStorage.getItem('profile')){
      req.headers.Authorization = `Bearer ${ JSON.parse(localStorage.getItem('profile')).token}`,
      req.headers.Accept = 'application/json'
  }
  return req;
});



const OddAndEven = props => {

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Suretrada | Odd and even calculator</title>
        </MetaTags>
        <Container>
          <Row>
            <Col md="12">
                <h3>Odd and Even Calculator</h3>
                <p>Coming soon</p>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default OddAndEven;

// DateFilter.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ChevronLeft, ChevronRight, ChevronUp, ChevronDown } from 'lucide-react';


const countries = ['England', 'France', 'Germany', 'Italy', 'Spain'];

const getDaysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();
const getFirstDayOfMonth = (year, month) => new Date(year, month, 1).getDay();
const formatDate = (date) => {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  return `${months[date.getMonth()]} ${date.getFullYear()}`;
};

const DateFilter = ({ onFilterChange }) => {
  const [selectedCountry, setSelectedCountry] = useState('England');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [hours, setHours] = useState(18);
  const [minutes, setMinutes] = useState(36);

  const handlePrevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  const handleDateClick = (day) => {
    setSelectedDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), day));
  };

  const handleHoursChange = (delta) => setHours((prev) => Math.min(23, Math.max(0, prev + delta)));
  const handleMinutesChange = (delta) => setMinutes((prev) => Math.min(59, Math.max(0, prev + delta)));

  const handleFilterClick = () => {
    const filterDate = new Date(selectedDate);
    filterDate.setHours(hours, minutes);
    onFilterChange(selectedCountry, filterDate);
  };

  const daysInMonth = getDaysInMonth(currentDate.getFullYear(), currentDate.getMonth());
  const firstDayOfMonth = getFirstDayOfMonth(currentDate.getFullYear(), currentDate.getMonth());

  const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
  const blanks = Array.from({ length: firstDayOfMonth }, (_, i) => i);

  return (
    <div className="date-filter">
      <div className="country-selector">
        {countries.map((country) => (
          <button
            key={country}
            className={`country-button ${selectedCountry === country ? 'selected' : ''}`}
            onClick={() => setSelectedCountry(country)}
          >
            {country}
          </button>
        ))}
      </div>

      <div className="filter-content">
        <h2 className="filter-title">DATE FILTER</h2>
        <div className="calendar-container">
          <div className="month-selector">
            <button onClick={handlePrevMonth}><ChevronLeft size={20} /></button>
            <span className="month-year">{formatDate(currentDate).toUpperCase()}</span>
            <button onClick={handleNextMonth}><ChevronRight size={20} /></button>
          </div>
          <div className="calendar">
            {['M', 'T', 'W', 'T', 'F', 'S', 'S'].map((day) => (
              <div key={day} className="day-name">{day}</div>
            ))}
            {[...blanks, ...days].map((day, index) => (
              <button
                key={index}
                className={`calendar-day ${
                  day === selectedDate.getDate() &&
                  currentDate.getMonth() === selectedDate.getMonth() &&
                  currentDate.getFullYear() === selectedDate.getFullYear()
                    ? 'selected'
                    : ''
                }`}
                onClick={() => day && handleDateClick(day)}
                disabled={!day}
              >
                {day || ''}
              </button>
            ))}
          </div>
          <div className="time-selector">
            <div className="time-unit">
              <button onClick={() => handleHoursChange(1)}><ChevronUp size={20} /></button>
              <span className="time-value">{hours.toString().padStart(2, '0')}</span>
              <button onClick={() => handleHoursChange(-1)}><ChevronDown size={20} /></button>
            </div>
            <span className="time-separator">:</span>
            <div className="time-unit">
              <button onClick={() => handleMinutesChange(1)}><ChevronUp size={20} /></button>
              <span className="time-value">{minutes.toString().padStart(2, '0')}</span>
              <button onClick={() => handleMinutesChange(-1)}><ChevronDown size={20} /></button>
            </div>
          </div>
        </div>
      </div>

      <button 
        className="filter-button"
        onClick={handleFilterClick}
      >
        DATE FILTER
      </button>
    </div>
  );
};

DateFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
};

export default DateFilter;
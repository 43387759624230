import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link, useParams, useHistory } from "react-router-dom";
import Iconfiy from "pages/Utility/Iconfiy"
import NumberFormat from 'react-number-format';
import ProgressBar from "@ramonak/react-progress-bar"
import  shortenRelativeTime from "pages/Utility/time"
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  ModalBody,
  Form,
  Label,
  Input,
  ModalHeader
} from "reactstrap";


import PotCom from "components/Common/PotCom";





const API_URL = process.env.REACT_APP_BASE_URL


const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use((req) => {
  if(localStorage.getItem('profile')){
      req.headers.Authorization = `Bearer ${ JSON.parse(localStorage.getItem('profile')).token}`,
      req.headers.Accept = 'application/json'
  }
  return req;
});

import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { toast } from "react-toastify";



const Pot = props => {


  const [showBalance, setShowBalance] = React.useState(false);
  const [modal, setModal] = useState(false)
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")))
  const [amount, setAmount] = useState(0)
  const [amountRaised, setAmountRaised] = useState(0)
  const [potDetails, setPotDetails] = React.useState()
  const [participants, setParticipants] = useState([])
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [balance, setBalance] = useState(0); 
  const [referalCode, setReferalCode] = useState("")
  const [managersNumber, setManagersNumber] = useState("")

  const link = `https://wa.me/${managersNumber}`

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };


  const getUserWallet = async () => {
    try {
      const response = await axiosApi.get(`${API_URL}/api/v1/user/naira-wallet`);
      setBalance(response.data.balance);
    } catch (error) {
      if (error.response?.data?.message === "Token is expired or invalid.") {
        localStorage.removeItem("profile");
      }
    }
  };


  const {id} = useParams();

 
  const fetchDocById = async (id) => {
    try {
      const docRef = firebase.firestore().collection('trading_pots').doc(id);
      // Fetch the document
      const doc = await docRef.get();
      if (doc.exists) {
        const docData = doc.data();
        const start_from = docData.trade_from?.toDate(); // Convert Firestore timestamp to JS Date
        const due_date = docData.trade_to?.toDate(); // Convert Firestore timestamp to JS Date
        const formattedData = { ...docData, trade_from: start_from ? start_from.toDateString() : null, trade_to: due_date ? due_date.toDateString() : null }; // Add the formatted timestamp back to the data
        setPotDetails(formattedData);
        setManagersNumber(formattedData?.managers_number || null);
      } else {
        toast("No such document")
        
      }
    } catch (err) {
      console.error('Error fetching document:', err);
      toast(err)
    }
  };

  const fetchParticipants = (id) => {
    setLoading(true);
    setError(null);

    const collectionRef = firebase.firestore().collection('pot_participants');

    // Subscribe to real-time updates and order by timestamp
    const unsubscribe = collectionRef
      .where('pot_id', '==', id)
      .orderBy('timestamp', 'desc')
      .onSnapshot(
        (snapshot) => {
          const newData = snapshot.docs.map((doc) => {
            const docData = doc.data();
            const timestamp = docData.timestamp?.toDate(); // Convert Firestore timestamp to JS Date
            const formattedTimestamp = timestamp ? shortenRelativeTime(timestamp) : 'N/A'; // Format the date
            return { id: doc.id, ...docData, formattedTimestamp };
          });
          setParticipants(newData);
          console.log(newData)
          setLoading(false);
        },
        (error) => {
          console.error("Error fetching participants: ", error);
          setError("Error fetching participants.");
          setLoading(false);
        }
      );

    // Cleanup subscription on component unmount
    return () => unsubscribe();
  };


  useEffect(() => {
    getUserWallet()
    fetchDocById(id)
    fetchParticipants(id)
   
  }, []);
  


  const withdraw = async () => {
    try {
      const today = new Date();
      const dueDate = potDetails?.trade_to; // Assuming trade_to is a Firestore Timestamp
      const status = potDetails?.status
  
      // Format both dates to only compare the date part
      // const formatDate = (date) => date.toISOString().split('T')[0];
      // const todayDate = formatDate(today);
      // const dueDateString = formatDate(dueDate);

      if (status === "Raising") {
        toast("Sorry, we are still raising and you can not withdraw at the moment.");
      }else if(status === "Trading"){
        toast(`You can not withdraw now as trading is going on, the due date is ${potDetails?.trade_to}`);
      }

     
  
      // if (todayDate !== dueDateString) {
      //   toast("Sorry, trading is still in progress. Try again on the due date.");
      // } else {
      //   // Proceed with withdrawal logic here
      // }
    } catch (error) {
      console.error("Error in withdraw function:", error);
      toast.error("An error occurred while attempting to withdraw.");
    }
  };
  




  const toggleBalanceVisibility = () => {
    setShowBalance((prevShowBalance) => !prevShowBalance);
  };

  const toggle = () => {
    setModal(!modal)
  }

  const updatePot = async () => {
    const collectionRef = firebase.firestore().collection('trading_pots').doc(id)
      collectionRef.update({ investors_count: parseInt(potDetails?.investors_count) + 1, raised_amount: parseInt(potDetails?.raised_amount) + parseInt(amount)})
  }
  
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const potVolume = parseInt(potDetails?.pot_volume);
      const raisedAmount = parseInt(potDetails?.raised_amount);
      const remainingAmount = potVolume - raisedAmount;
      const roi = (parseInt(potDetails?.percentage) / 100) * parseInt(amount)
      const total = roi + parseInt(amount)
  
      if (parseInt(amount) > balance) {
        toast.error("Insufficient funds");
      } else if (parseInt(amount) > potVolume) {
        toast.error(`Maximum amount to join with is ₦${potVolume}`);
      } else if (parseInt(amount) < 10000) {
        toast.error(`Minimum amount to join with is ₦10,000`);
      } else if (amount > remainingAmount) {
        toast.error(`Amount can only be ₦${remainingAmount} or less`);
      } else {
        const collectionRef = firebase.firestore().collection('pot_participants');
        // Add new document with message and current timestamp
        const potPayload = {
          name: user?.data.first_name,
          pot_id: id,
          total: total,
          roi: roi,  // Correct ROI calculation
          trading_capital: amount,
          status: "Pending",
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          user_id: user?.data.id
        };
        await collectionRef.add(potPayload);
        await updatePot();
        await fetchDocById(id);
        const data = {
          amount: amount,
          pot_id: id,
          referalCode: user?.data.referred_by
        }
        await axiosApi.post(`${API_URL}/api/v1/user/join-auto-trada`, data);
        toast.success("Pot joined");
        toggle();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  

const balanceCard = () => {
    return (
        <>
   <Card style={{ borderRadius: "10px", backgroundColor: "rgb(76 46 135)", marginTop: "10px"}}>
      <div className="text-center" style={{ paddingTop: "30px"}}>
        <h5 style={{ color: "#ffff"}}>Pot Volume</h5>
        <div className='d-flex flex-row justify-content-center align-items-center'>
          <h2 style={{ color: "#fff", fontWeight: "bolder"}}>
             {
              showBalance ? (
              <>
              <NumberFormat value={potDetails?.pot_volume} className="foo" displayType={'text'} thousandSeparator={true} renderText={(value, props) => <div {...props}>&#8358;{value}</div>}/> 
              </>) : (
              <>
               <span>₦{"*".repeat(potDetails?.pot_volume.length - 3) + "." + '*'.repeat(2)}</span>
              </>)
             }
            </h2>
               <div style={{ marginLeft: '7px',cursor: 'pointer', color: "#fff" }} onClick={toggleBalanceVisibility}>
               <Iconfiy icon={showBalance ? "ion:eye-outline" : "ion:eye-off-outline"}/>
          </div>
        </div>
      </div>
    
    
      

      <div style={{ paddingBottom: "10px", margin: "auto", marginTop: "15px", overflowX: "auto", maxWidth: "100" }}>
        { potDetails?.status === "Trading" ? (<></>) :  potDetails?.status === "Raising" ? (<></>) :  (<>  <button onClick={() => { withdraw() }} disabled className='btn btn-default temp-contain' style={{ border: "1px solid black", backgroundColor: "#000", color: "white" }}> Paidout</button></>)  }
        {/* {user?.data.subscription ? null :  <button disabled={loading ? true : false} onClick={() => { subscribe() }} className='btn btn-default temp-contain' style={{ border: "1px solid black", backgroundColor: "#000", color: "white", marginLeft: "2px" }}><Iconfiy icon="solar:arrow-up-outline" angle="225" width="20px" /> {loading ? "Loading.." : "Subscribe"}</button>} */}
       {potDetails?.status === "Raising" && <button onClick={() => setModal(true)} className='btn btn-default temp-contain' style={{ border: "1px solid black", backgroundColor: "#000", color: "white", marginLeft: "2px" }}><Iconfiy icon="ic:round-plus" width="13px" />Join</button> } 
    </div>


      
     </Card>
        </>
    )
}



  return (
    <React.Fragment>
      
      <div className="page-content">
        <MetaTags>
          <title>SureTrada | Auto-trada </title>
          <description>We trade sport while you earn.</description>
        </MetaTags>
        <Container style={{ justifyContent: "center"}}>
           <Row>
             <Col md="10">
               <Row>
               <Col md="8">
                 <Row>
                    <Col md="12">
                          <Link to="/auto-trada" style={{ paddingBottom: "10px"}}><Iconfiy icon="ic:sharp-arrow-back" width="15px" />Back to pots</Link>
                         {balanceCard()}
                    </Col>


                
                      
                    <div style={{ borderRadius: "10px", backgroundColor: "white", padding: "30px 20px 30px 20px "}}>
                       <span>Pot Details</span><span style={{ float: "right", color: "rgb(76 46 135)"}}>    <Iconfiy onClick={toggleShowMore} icon={showMore ? "ion:eye-outline" : "ion:eye-off-outline"}/> </span>  <br/>
                       <hr />
                      {showMore &&
                          (
                          <>
                              <span>Raised</span><span style={{ float: "right", color: "rgb(76 46 135)"}}>  <NumberFormat value={potDetails?.raised_amount} className="foo" displayType={'text'} thousandSeparator={true} renderText={(value, props) => <div {...props}>&#8358;{value}</div>}/> </span> <br/>
                              <span>Percentage</span><span style={{ float: "right", color: "rgb(76 46 135)"}}>{potDetails?.percentage }%</span> /Month <br/>
                              <span>Starts On</span><span style={{ float: "right", color: "rgb(76 46 135)"}}>{potDetails?.trade_from}</span>  <br/>
                              <span>Due date</span><span style={{ float: "right", color: "rgb(76 46 135)"}}>{potDetails?.trade_to}</span>  <br/>
                              <span>ROI</span><span style={{ float: "right", color: "rgb(76 46 135)"}}>  <NumberFormat value={(parseInt(potDetails?.percentage) / 100) * parseInt(potDetails?.pot_volume) + parseInt(potDetails?.pot_volume)} className="foo" displayType={'text'} thousandSeparator={true} renderText={(value, props) => <div {...props}>&#8358;{value}</div>}/> </span> <br/>
                              <span>Status</span><span style={{ float: "right", color: "green"}}> {potDetails?.status} </span> <br/>
                              <span>Duration</span><span style={{ float: "right", color: "green"}}> {potDetails?.duration} {potDetails?.duration > 1 ? "Months" : "Month"} </span> <br/>
                              <span>Type</span><span style={{ float: "right", color: "green"}}> {potDetails?.type}</span> <br/>
                              <span>Manager</span><span style={{ float: "right", color: "green"}}> {potDetails?.manager}</span> <br/>
                              <span>Manager&rsquo;s Whatsapp</span><span style={{ float: "right", color: "green"}}>  <a style={{ color: "rgb(76 46 135)"}} href={link} target="_blank" rel="noopener noreferrer">
                                    Click to chat
                               </a></span> <br/>
                              <hr />
                            
                                <h6>Pot Terms</h6>
                                <p>
                                  {potDetails?.terms}
                                </p>
                          </>)
                       }
                   
          

                   </div>
                   

                  
                  

                

                    <Col md="12" xm="12" sm="12" style={{ backgroundColor: "white", paddingTop: "20px", borderRadius: "10px", marginTop: "20px"}}>
                      <h6>Pot participants({potDetails?.investors_count})</h6> 
                      <hr />
                      <div>
                           {participants.length > 0 ? (
                            <>
                              {participants.map(participant => (
                                <> 
                                  <PotCom amount={participant?.trading_capital} status={participant?.status} roi={participant.roi * potDetails?.duration} user_name={participant?.name} />
                                </>
                              ))}
                            </>
                            ) : (
                            <>
                             <p>No participant yet</p>
                            </>
                            )}
                         
                      </div>
                    </Col>
                 </Row>
             
                   </Col>
                   
               </Row>
             </Col>
           </Row>

          <Modal   isOpen={modal} toggle={toggle}  size="md" centered={true} >
          <ModalHeader toggle={toggle} tag="h6" >5% referral bonus</ModalHeader>
              <ModalBody>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                        return false;
                    }}>
                         <Row form>
                                <Col xs={12}>
                                    <Row>
                                       <Col md="12">
                                          <div className="mb-3">
                                            <Label className="form-label">Trading amount</Label>
                                            <Input
                                              name="amount"
                                              type="number"
                                              placeholder="enter amount"
                                              onChange={(e) => setAmount(e.target.value)}
                                              />
                                           </div>
                                           <span>Minimum of ₦10,000</span>
                                           <div className="invalid-feedback">{error}</div>
                                        
                                       </Col>

                                      
                                    </Row>
                                 </Col>
                                </Row>

                                <Row>
                                <Col>
                                    <div className="text-end">

                                    <button
                                        type="submit"
                                        disabled={ amount == "" || loading ? true : false  }
                                        className="btn btn-default"
                                        style={{ backgroundColor: "#5719D0", color: "#fff"}}
                                    >
                                        {loading ? (
                                          <>
                                            Loading....
                                          </>
                                          ) : (
                                          <>
                                           Join now
                                          </>)}
                                    </button>
                                    </div>
                                </Col>
                                </Row>
                            
                            </Form>
              </ModalBody>
        </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

Pot.propTypes = {
  t: PropTypes.any,
  userDetails: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default Pot

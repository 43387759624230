import React, { useState, useEffect } from 'react';
import axios from "axios";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button
} from "reactstrap";
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import PropTypes from "prop-types";
import Iconfiy from 'pages/Utility/Iconfiy';
import Loader from 'pages/Utility/Loader';
import { useHistory } from 'react-router-dom';
import shortenRelativeTime from "pages/Utility/time"

const API_URL = process.env.REACT_APP_BASE_URL;

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use((req) => {
  if (localStorage.getItem('profile')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    req.headers.Accept = 'application/json';
  }
  return req;
});

export default function GameDetailsModal({ gameDetailModal, toggle, gameDetails, user }) {
  const [loading, setLoading] = useState(false);
  const [gameCode, setGameCode] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (gameDetails?.id) {
      if (gameDetails.status === "Ended") {
        localStorage.removeItem(`gameCode_${gameDetails.id}`);
        setGameCode("");
      } else {
        const storedCode = localStorage.getItem(`gameCode_${gameDetails.id}`);
        setGameCode(storedCode || "");
      }
    }
  }, [gameDetails]);

  const getOrBuyGame = async () => {
    setLoading(true);
    try {
      const data = {
        game_id: gameDetails?.id,
        amount: gameDetails?.amount,
      };

      const response = await axiosApi.post(`${API_URL}/api/v1/puntershub/buy-or-get-game`, data);

      if (response.status === 200) {
        const newGameCode = response.data.game.game_code;
        setGameCode(newGameCode);
        localStorage.setItem(`gameCode_${gameDetails.id}`, newGameCode);
        toast.success(gameDetails?.amount === 0 ? "Game code retrieved successfully" : "Game bought successfully");
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'An error occurred');
      if (error.response?.data?.message === "Token is expired or invalid.") {
        localStorage.removeItem("profile");
        history.push("/login");
      }
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(gameCode)
      .then(() => {
        toast.success('Code copied');
      })
      .catch(err => {
        console.error('Failed to copy code to clipboard:', err);
        toast.error('Failed to copy code');
      });
  };

  const deleteGame = async () => {
    setLoading(true);
    try {
      await axiosApi.delete(`/api/v1/games/${gameDetails?.id}`);
      localStorage.removeItem(`gameCode_${gameDetails.id}`);
      setLoading(false);
      toggle();
      toast.success('Game successfully deleted!');
    } catch (error) {
      setLoading(false);
      toast.error('Error deleting game: ' + (error.response?.data?.message || 'An unknown error occurred'));
    }
  };

  return (
    <Modal isOpen={gameDetailModal} toggle={toggle} size="md" centered={true}>
      <ModalHeader toggle={toggle} tag="h4"><h5>Punter Details</h5></ModalHeader>
      <ModalBody>
        <div style={{ borderRadius: "10px", border: "1px solid black", padding: "10px", marginTop: "20px" }}>
          <span>Name</span> <span style={{ float: "right" }}>{gameDetails?.punter_name}</span> <br />
          <span>Total games posted</span> <span style={{ float: "right" }}>{gameDetails?.game_count}</span> <br />
          <span>Total games won</span> <span style={{ float: "right" }}>{gameDetails?.win_count}</span> <br />
          <span>Total games lost</span> <span style={{ float: "right" }}>{gameDetails?.lost_count}</span> <br />
          <span>Punting power</span> <span style={{ float: "right" }}>{gameDetails?.punting_power}</span> <br />
          <hr />

          <h5>Game Details</h5>
          <span>Title</span> <span style={{ float: "right" }}>{gameDetails?.title}</span> <br />
          <span>Price</span> <span style={{ float: "right" }}>
            <NumberFormat value={Math.round(gameDetails?.amount)} className="foo" displayType={'text'} thousandSeparator={true} renderText={(value, props) => <div {...props}>&#8358;{value}</div>} />
          </span> <br />
          <span>Status</span> <span style={{ float: "right" }}>{gameDetails?.status}</span> <br />
          <span>Total Odd</span> <span style={{ float: "right" }}>{gameDetails?.odd}</span> <br />
          <span>Total Buyers</span> <span style={{ float: "right" }}>{gameDetails?.buyers_count}</span> <br />
          <span>Platform</span> <span style={{ float: "right" }}>{gameDetails?.platform}</span> <br />
          <span>Start Time</span> <span style={{ float: "right" }}>{shortenRelativeTime(gameDetails?.startDate)}</span> <br />
          <span>End Time</span> <span style={{ float: "right" }}>{shortenRelativeTime(gameDetails?.endDate)}</span> <br />
          {user.data.id === gameDetails?.user_id && (
            <>
              <span>Expected Profit</span> <span style={{ float: "right" }}>
                <NumberFormat value={Math.round(gameDetails?.possible_profit)} className="foo" displayType={'text'} thousandSeparator={true} renderText={(value, props) => <div {...props}>&#8358;{value}</div>} />
              </span> <br />
            </>
          )}
          <hr />

          {gameCode ? (
            <>
              <span>Game Code</span>
              <p style={{ fontSize: "50px", color: "#5719D0" }}>
                {gameCode} <sub><Iconfiy color="red" onClick={copyToClipboard} icon="ph:copy" /></sub>
              </p>
            </>
          ) : (
            <>
              {gameDetails?.status === "Active" && (
                <Button
                  onClick={getOrBuyGame}
                  className='btn btn-default temp-contain'
                  style={{ border: "1px solid gray", backgroundColor: "#5719D0", color: "white" }}
                >
                  {loading && <Loader />}
                  {gameDetails?.amount === 0 ? 'Get game code' : 'Buy game code'}
                </Button>
              )}
              {gameDetails?.status !== "Active" && <p>Game has ended</p>}
            </>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
}

GameDetailsModal.propTypes = {
  gameDetailModal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  gameDetails: PropTypes.object,
  user: PropTypes.object
};
import React, { useState, useEffect } from 'react'
import MetaTags from "react-meta-tags";
import { Container, Row, Col, Card } from "reactstrap"
import DashSide from '../Dashboard/DashSide'
import Iconfiy from 'pages/Utility/Iconfiy'
import ProfileCom from "../../components/Common/ProfileCom"
import GeneralCard from "../../components/Common/GeneralCard"

import PropTypes from 'prop-types'
import RefCard from 'components/Common/RefCard';


const API_URL = process.env.REACT_APP_BASE_URL

import axios from "axios";
const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use((req) => {
  if(localStorage.getItem('profile')){
      req.headers.Authorization = `Bearer ${ JSON.parse(localStorage.getItem('profile')).token}`,
      req.headers.Accept = 'application/json'
  }
  return req;
});


const Profile = props => {

    const [userBanks, setUserBanks] = useState([])
    const [balance, setBalance] = useState(0)
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")))
    const [referrals, setReferrals] = useState([])


  const getUserWallet = async () => {
    axiosApi.get(`${API_URL}/api/v1/user/naira-wallet`).then((res) => {
      setBalance(res.data.balance)
    }).catch((error) => {
     console.log(error)
    })
  }
  
  const getUserBanks = async () => {
    axiosApi.get(`${API_URL}/api/v1/user/banks`).then((res) => {
      setUserBanks(res.data.data)
    }).catch((error) => {
  
    })
  }

  const getUserReferrals = async () => {
    axiosApi.get(`${API_URL}/api/v1/user/my-referrals`).then((res) => {
      setReferrals(res.data)
    }).catch((error) => {
  
    })
  }


  useEffect(() => {
    getUserWallet()
    getUserBanks()
    getUserReferrals()
  }, [])

  const sayHello = () => {
    localStorage.removeItem('profile')
    props.history.push("/login")
  }



    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                <title>SureTrada | My Profile</title>
                </MetaTags>

                    <Container>
                        <Row>
                        <Col md="10">
                            <Row>
                                <Col md="5">
                                <Card style={{ borderRadius: "10px", backgroundColor: "white"}}>
                                    <DashSide balance={balance} userBanks={userBanks}/>
                                </Card>
                                </Col>

                                <Col md="6">
                                    <div className='' style={{ display: "flex", flexDirection: "row", gap: "239px"}}>
                                    <h2>My Profile</h2>
                                    </div>
                                     <hr />
                                     <ProfileCom user={user} refLength={referrals.length} />

                                     <br />

                                     <div className='plan-head'>
                                      <h4 style={{ marginBottom: '20px' }}>My Referrals</h4>

                                      {referrals.length > 0 ? (
                                          referrals.map((ref) => (
                                            <>
                                            <RefCard text={ref.first_name + " " + ref.last_name} status={ref.subscription} date={ref.createdAt} />
                                          </>
                                          ))
                                        
                                      ) : (
                                          <>
                                            <p>You have no referral..</p>
                                          </>
                                      )}
                                     
                                    </div>

                                   

                                 
                                    <GeneralCard title="Logout" details="" onclick={() => sayHello()} />

                                </Col>
                            </Row>
                        </Col>
                        </Row>
                    </Container>
            </div>
        </React.Fragment>
    )
}
export default  Profile


Profile.propTypes = {
  history: PropTypes.any
}

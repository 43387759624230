import React from 'react'
import PropTypes from 'prop-types';
import Iconfiy from 'pages/Utility/Iconfiy'
import { Link } from "react-router-dom";


const GeneralCard = ({title, details, onclick}) => {
  return (
    <div className='temp-contain d-flex flex-row align-items-center'  onClick={onclick}>
      <div className='spooIcon'>
         <Iconfiy icon="majesticons:logout"  color="#000"/>
      </div>

      <div style={{ flexGrow: "1" }}>
        <h6>{title}</h6>
        <p style={{ fontWeight: "bold", color: "#5719D0"}}>{details}</p>
      </div>

     
    </div>
  )
}

export default GeneralCard


GeneralCard.propTypes = {
  title: PropTypes.any,
  details: PropTypes.any,
  id: PropTypes.any,
  link: PropTypes.any,
  onclick: PropTypes.func,
}


  
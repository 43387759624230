import React, {useState, useEffect} from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from "prop-types";
import { withRouter, Link, useHistory } from "react-router-dom";

import { Row, Col, CardBody, Card, Container } from "reactstrap";
import 'react-toastify/dist/ReactToastify.min.css';


import logolight from "../../assets/images/logo.png";
import batchImage from "../../assets/images/batch.png";
import timeImage from "../../assets/images/time.png";


const Waiting = props => {
   
    const history = useHistory();

    const [fullname, setFullname] = useState("")

    useEffect(() => {
        if (localStorage.getItem("user")) {
          const obj = JSON.parse(localStorage.getItem("user"));
          const name = obj.name
          setFullname(name)
        }
      }, []);

   


 


    

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-5">
        <MetaTags>
          <title>Expired subscription | SureTrada</title>
        </MetaTags>
        <Container>
        <Row className="justify-content-center">
           <Col md={4}>
              <Card style={{ borderRadius: "16px", border:" 2px solid #E7E9EB"}}>
                <CardBody>
                <img src={logolight}   width="20%"  className="auth-logo-light" />
                      <hr />
                     <h4 
                     style={{ 
                        width: "219px",
                        height: "30px",
                        fontFamily: 'Euclid Circular A',
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "10px",
                        lineHeight: "30px",
                       
                        color: "#000000",
                        }}>Expired Subscription</h4>

                       <p style={{ 
                        width: "219px",
                        height: "40px",
                        fontFamily: 'Euclid Circular A',
                        fontStyle: "normal",
                        fontWeight: "500",
                        color: "#717171",
                        textAlign: "center"
                       }}>
                   
                        Hi {fullname},Please kindly click on the button below to re-activate your account.
                       
                      </p>

                    

                      <button style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        marginTop: "30px",
                        alignItems: "center",
                        padding: "11px 20px",
                        gap: "10px",
                        width: "100%",
                        color:"white",
                        height: "42px",
                        backgroundColor: "#000",
                        border: "none",
                        borderRadius: "8px",
                      }} onClick={() => history.push("/login")} >Re-activate.</button>
                </CardBody>
              
              </Card>
           </Col>
         </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Waiting)


Waiting.propTypes = {
  history: PropTypes.object,
};

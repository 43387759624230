import React from 'react'
import PropTypes from 'prop-types';
import Iconfiy from 'pages/Utility/Iconfiy'
import { Link } from "react-router-dom";



const Services = ({title, details, id, link}) => {
  return (
    <Link to={link}>
    <div className='temp-contain d-flex flex-row align-items-center' >
      <div className='spooIcon'>
         {id === 1 ?     <Iconfiy icon="fluent-mdl2:profile-search"  color="#5719D0"/>: 
          id === 2 ?     <Iconfiy icon="healthicons:referral-outline"  color="green"/> :
          id === 3 ?     <Iconfiy icon="material-symbols-light:casino-outline"  color="#5719D0"/> :
          id === 4 ?     <Iconfiy icon="mynaui:mobile-signal-five"  color="red"/> :
          id === 5 ?     <Iconfiy icon="solar:calculator-minimalistic-broken"  color="orange"/> :
          id === 6 ?     <Iconfiy icon="mdi:calculator"  color="#000"/> :
          id === 7 ?     <Iconfiy icon="ph:video-light"  color="red"/> :
          id === 8 ?     <Iconfiy icon="hugeicons:trade-up"  color="rgb(76 46 135)"/> :
            null}
      </div>

      <div style={{ flexGrow: "1" }}>
        <h4>{title}</h4>
        <p>{details}</p>
      </div>

      <Iconfiy icon="ep:arrow-right"/>
    </div>
    </Link>
  )
}

export default Services


Services.propTypes = {
  title: PropTypes.any,
  details: PropTypes.any,
  id: PropTypes.any,
  link: PropTypes.any
}


  
// GoalsChart.js
import React from 'react';
import PropTypes from 'prop-types';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const GoalsChart = ({ games }) => {
  const chartData = games.map((game, index) => ({
    name: `Game ${index + 1}`,
    'Home Goals': game.homeScore,
    'Away Goals': game.awayScore,
  }));

  return (
    <div className="goals-chart">
      <h2 className="chart-title">Goals per Game</h2>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Home Goals" fill="#8884d8" />
          <Bar dataKey="Away Goals" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

GoalsChart.propTypes = {
  games: PropTypes.arrayOf(
    PropTypes.shape({
      homeScore: PropTypes.number.isRequired,
      awayScore: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default GoalsChart;
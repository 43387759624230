import PropTypes from 'prop-types';
import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

// Import menuDropdown
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";



import logo from "../../assets/images/logo.png";
import menuIcon from "../../assets/images/FlipEx/collapse.svg";
import xmass from "../../assets/images/FlipEx/xmass.jpg";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";



const API_URL = process.env.REACT_APP_BASE_URL


const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use((req) => {
  if(localStorage.getItem('profile')){
      req.headers.Authorization = `Bearer ${ JSON.parse(localStorage.getItem('profile')).token}`
  }
  return req;
});


const Header = props => {
  const [search, setsearch] = useState(false);
  const [megaMenu, setmegaMenu] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);



//  const [notifications, setNotifications] = useState([])

  

//   const MINUTE_MS = 100000;

//     useEffect(() => {
//       const interval = setInterval(() => {
//        // dispatch(onGetReports())
//        axiosApi.get(`${API_URL}/` + 'api/v1/admin/notifications').then((res) => {
//         setNotifications(res.data); 
//     }).catch((errors) => {
//         // return errors
//         toast(errors.message)
//       console.log(errors);
//     })
//       }, MINUTE_MS);

//       return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
//     }, [])



  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }



  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">

            <div className="navbar-brand-box d-lg-none d-md-block">
               {/* Display logo on both small and large devices */}
               <Link to="/dashboard" className="logo logo-dark">
                <img src={logo} alt="" height="50" />
              </Link>
              <Link to="/dashboard" className="logo logo-light">
                <img src={logo} alt="" height="50" />
              </Link>
            </div>

           

           

         </div>
      
         {/* <marquee style={{  fontWeight: "bolder", paddingLeft: "10px"}}><img src={xmass} width={50} height={50} style={{ borderRadius: "100px"}} /><span style={{ paddingLeft: '10px', fontSize: "20px", color: "green"}}><span style={{ color: "red"}}>Season Greetings!</span> Wishing you and your family a blessed holiday season, as you create beautiful memories together.</span></marquee> */}
          <div className="d-flex">
          <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
               <i className="fa fa-fw fa-bars" /> 
             
            </button>
            <NotificationDropdown/> 
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));

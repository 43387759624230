import Iconfiy from 'pages/Utility/Iconfiy'
import React from 'react'
import sporty from "assets/images/images.png"
import PropTypes from "prop-types";
import GameDetailsModal from 'components/Common/GameDetailsModal'
import { ToastContainer, toast } from 'react-toastify';

import firebase from "firebase/compat/app";
import "firebase/compat/database";

const TopGamesCard = ({ onclick, games, onpress}) => {
  const [gameDetails, setGameDetails] = React.useState("")
  const [gameDetailModal, setGameDetailModal] = React.useState(false)
  const [user, setUser] = React.useState(JSON.parse(localStorage.getItem("profile")))


  const toggle = () => {
    setGameDetailModal(!gameDetailModal)
  }


  const openGameDetails = (game) => {
    setGameDetails(game)
    setGameDetailModal(true)
  }

  const deleteAllDocuments = async (collectionName) => {
    try {
      // Get a reference to the collection
      const collectionRef = firebase.firestore().collection(collectionName);
      
      // Get all documents in the collection
      const querySnapshot = await collectionRef.get();
      
      // Delete each document
      querySnapshot.forEach(async (doc) => {
        await doc.ref.delete();
      });
      toast.success('All documents successfully deleted!')
    } catch (error) {
      console.error(error)
      toast.error('Error deleting documents: ', error)
    }
  };
  

  const handleDeleteAll = async () => {
    const collectionName = 'games';
    await deleteAllDocuments(collectionName);
  };

  

  return (
    <React.Fragment>
        <GameDetailsModal gameDetailModal={gameDetailModal} toggle={toggle} gameDetails={gameDetails} user={user} />
      <div className='d-flex flex-column wallet-view' style={{ gap: '20px', backgroundColor: "#5719D0" }}>
          <div className='d-flex justify-content-between align-items-center wallet-atn'>
            <div className='d-flex align-items-center' style={{ gap: '10px' }}>
              { user?.data.can_post_game &&  <button className='active' onClick={onclick}>Add Game</button>}
              { !user?.data.can_post_game &&  <button className='active' onClick={onpress}>Become a punter</button>}
              { user?.data.role === 1  && <button className='active' onClick={handleDeleteAll}>Clear Games</button>}
              
            </div>
          </div>

          <div className="wallet-slider-container">
            <div className="d-flex overflow-x-auto all-wallets no-scrollbar" style={{ gap: '20px', overflowX: 'auto', scrollSnapType: 'x mandatory' }}>
               
                {
                  games.length == 0 ? (
                  <>
                    <p style={{ color: "white"}}>Waiting for games with bigger Odds</p>
                  </>
                  ) : (
                    <>
                       {
                        games.map(game => (
                            <>

                               <div className='indiv-wallet' style={{ backgroundColor: "white"}}>
                                    <div className='top-part d-flex justify-content-between'>
                                    <div className='d-flex' style={{ gap: "8px" }}>
                                        <img className='image' src={sporty} width={40} alt='' />
                                        <div className='d-flex align-items-center'><span style={{ fontWeight: "bold", color: "black"}}> {game.title}</span></div>
                                    </div>
                                    <div className="waiting-signal">
                                        {/* <p style={{ color: "green", fontWeight: "bolder", textAlign: "center"}}>Playing...</p> */}
                                        </div>
                                    </div>

                                    <div className='bottom d-flex flex-column'>
                                    <div className='d-flex justify-content-between align-items-center mb-2' style={{ width: '70%' }}>
                                        <h2 style={{ color: "black"}} ><span>₦</span>{game.amount}</h2>
                                        <Iconfiy color="black" onClick={() => openGameDetails(game)} icon="ion:eye"/>
                                    </div>
                                
                                    </div>
                                </div>

                                
                        
                            </>


                        ))
                       }
                    </>
                  )
                }
            
             
            </div>
          </div>
        </div>
    </React.Fragment>
  )
}

export default TopGamesCard

TopGamesCard.propTypes = {
  onclick: PropTypes.any,
  games: PropTypes.any,
  onpress: PropTypes.any,

}

import PropTypes from "prop-types";
import React, {useState} from 'react'
import { MetaTags } from 'react-meta-tags'
import { Container, Input, FormFeedback, Alert, Row, Col, InputGroup, InputGroupAddon, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import Loader from 'pages/Utility/Loader'
import Iconify from '../Utility/Iconfiy'
import axios from "axios";
import Logo from "../../assets/images/logo.png"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";


const API_URL = process.env.REACT_APP_BASE_URL;


const axiosApi = axios.create({
  baseURL: API_URL,
});





const RegisterUser = props => {
  const [tabNum, setTabNumber] = React.useState(0);
  const [loader, setLoader] = React.useState(false);
  const [viewPass, setViewPass] = React.useState(false);
  const [gender, setGender] = React.useState('female');
  const [avatar_url, setAvatarUrl] = React.useState("")
  const [errors, setErrors] = React.useState([])
  const [error, setError] = React.useState("")
  const [isTermsChecked, setIsTermsChecked] = React.useState(false)
  const [isAgeChecked, setIsAgeChecked] = React.useState(false)

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

   


  const handleTabChange = (e) => {
    e.preventDefault();
    setTabNumber(tabNum + 1);
  }

  const handleTabChangePrev = (e) => {
    e.preventDefault();
    setTabNumber(tabNum - 1);
  }
  
  const handlePassView = (e) => {
    e.preventDefault();
    setViewPass(!viewPass);
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      first_name: Yup.string().required("Please Enter Your FirstName"),
      last_name: Yup.string().required("Please Enter Your LastName"),
      phone_number: Yup.string().required("Please Enter Your Phone number"),
      username: Yup.string().required("Please enter your preferred username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      setLoader(true)
      const data = {
        email: values['email'],
        password: values['password'],
        first_name: values['first_name'],
        last_name: values['last_name'],
        username: values['username'].trimEnd(),
        phone_number: values['phone_number'],
        device_type: "WEB",
        device_token: "123",
        referral_code: values['referral_code'],
        marketing_channel: "Others",
        gender: gender,
        country: values['country'],
        avatar_url: gender === 'male' ? 'https://edgeequitys.s3.amazonaws.com/male.png' : 'https://edgeequitys.s3.amazonaws.com/female.png'
      }
      axiosApi.post(`${API_URL}/api/v1/user/register`, data).then((res) => {
       setLoader(false)
      
       localStorage.setItem("profile", JSON.stringify(res.data));
       props.history.push("/dashboard");
      }).catch((error) => {
        setLoader(false)
        if(error.response.data.status === false){
          setError(error.response.data.message)
        }else{
          setErrors(error.response.data.error)
        }

      })
    }
  });

  const floatButtonStyle = {
    position: 'fixed',
    bottom: '120px',
    right: '20px',
    zIndex: 1000,
  };
  
  const whatsappButtonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50px',
    height: '50px',
    backgroundColor: '#25D366',
    borderRadius: '50%',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    color: '#fff',
  };


  const toggleTerms = () => {
      setIsTermsChecked(!isTermsChecked)
  }

  const toggleAge = () => {
    setIsAgeChecked(!isAgeChecked)
  }


  return (
    <React.Fragment>
      <div>
        <MetaTags>
        <title>Register |  Punters and Traders hub</title>
        </MetaTags>

        <Container>
        {loader && <Loader/>}
          <div className='main-contentyu container-fluid d-flex justify-content-center'>
           <div className={`major-width con-rel  ${tabNum === 0 ? '' : 'continue'}`}>
              <div className='text-center migi'>
              <img src={Logo} alt=""  height={100} />
                {
                  tabNum === 0 ? (
                    <>
                      <h6>Punters and Traders hub!</h6>
                      <h2>Create an account</h2>
                    </>
                  ) : (
                    <>
                      <h2>Finish up the signup process</h2>
                    </>
                  )
                }
              </div>

              {errors?.length > 0 ? 
               errors.map((err) => (
                <>
                   <Alert color="danger">{err.message}</Alert> 
                </>
               ))
              : 
              null}
              {error ? <Alert color="danger">{error}</Alert>  : null}

              <form className='registration-container'                     
                      onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}>
                <div className={`registration-tab ${tabNum === 0 ? 'active' : ''}`}>
                  <div className='input-set'>
                    <label className='form-label '>Firstname</label>
                    <Input
                        name="first_name"
                        className="form-control"
                        placeholder="Enter your first name..."
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.first_name || ""}
                        
                        invalid={
                          validation.touched.first_name && validation.errors.first_name ? true : false
                        }
                      />

                            
                      {validation.touched.first_name && validation.errors.first_name ? (
                        <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                      ) : null}
                  </div>

                  <div className='input-set'>
                    <label className='form-label'>Lastname</label>
                    <Input
                        name="last_name"
                        className="form-control"
                        placeholder="Enter your last name..."
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.last_name || ""}
                        
                        invalid={
                          validation.touched.last_name && validation.errors.last_name ? true : false
                        }
                      />

                            
                      {validation.touched.last_name && validation.errors.last_name ? (
                        <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                      ) : null}

                  </div>

                  <Row>
                      <Col md="6">
                      <div className='input-set'>
                          <label className='form-label'>Username</label>
                          <Input
                              name="username"
                              className="form-control"
                              placeholder="Enter your last name..."
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.username || ""}
                              
                              invalid={
                                validation.touched.username && validation.errors.username ? true : false
                              }
                            />

                                  
                            {validation.touched.username && validation.errors.username ? (
                              <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                            ) : null}

                        </div>
                      </Col>
                      <Col md="6">
                         <div className='input-set'>
                          <label className='form-label'>Country</label>
                           <select className="form-control" name="country"  onChange={validation.handleChange}>
                              <option>Select your country</option>
                              <option value="nigeria">Nigeria</option>
                           </select>

                                  
                            {validation.touched.country && validation.errors.country ? (
                              <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                            ) : null}

                        </div>
                      </Col>
                  </Row>

                 

                  <div className='input-set mb-3'>
                    <label className='form-label'>Email Address</label>
                    <Input
                        name="email"
                        className="form-control"
                        placeholder="Enter your last name..."
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        
                        invalid={
                          validation.touched.email && validation.errors.email ? true : false
                        }
                      />

                            
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                      ) : null}
                  </div>

                  <button className='btn btn-primary full mb-2' onClick={handleTabChange}>Let&apos;s go there..</button>

                  <div className='register-prompt'>Have an account with us?<span><a href='/login' > Sign in here</a></span></div>

                </div>

                <div className={`registration-tab ${tabNum === 1 ? 'active' : ''}`}> 
                  <div className='input-set'>
                    <label className='form-label '>Password</label>
                    <div className='passwordIput-container'>
                    <Input
                        name="password"
                        value={validation.values.password || ""}
                        type={viewPass ? "text" : "password"}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.password && validation.errors.password ? true : false
                        }
                      />
                      
                      <div className='nigga' onClick={handlePassView}>
                        <Iconify icon={viewPass ? "ion:eye-outline" : "ion:eye-off-outline"}/>
                      </div>

                      {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                    </div>
                  </div>

                  <div className='input-set'>
                      <label className='form-label'>Phone Number</label>
                      <div className='form-control phone-input-container'>
                
                          <Input
                            name="phone_number"
                            className="form-control mine-input num-sel"
                            placeholder="08062515720"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone_number || ""}
                            invalid={validation.touched.phone_number && validation.errors.phone_number}
                          />
                          {validation.touched.phone_number && validation.errors.phone_number && (
                            <FormFeedback type="invalid">{validation.errors.phone_number}</FormFeedback>
                          )}
                    
                      </div>
                    </div>

                  <div className='input-set'>
                    <label className='form-label'>Gender</label>
                    <div className='gender'>
                      <div className={`gender-pick ${gender === 'male' ? 'active' : ''}`} onClick={() => setGender('male')}>
                        Male
                      </div>

                      <div className={`gender-pick ${gender === 'female' ? 'active' : ''}`} onClick={() => setGender('female')}>
                        Female
                      </div>
                    </div>
                  </div>

                  <div className='input-set'>
                    <label className='form-label'>Referral Code (Optional)</label>
               
                    <Input
                        name="referral_code"
                        className="form-control"
                        placeholder="Enter referral code..."
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.referral_code || ""}
                        
                        invalid={
                          validation.touched.referral_code && validation.errors.referral_code ? true : false
                        }
                      />

                            
                      {validation.touched.referral_code && validation.errors.referral_code ? (
                        <FormFeedback type="invalid">{validation.errors.referral_code}</FormFeedback>
                      ) : null}
                  </div>

                  <div className='policy'>
                    <input type='checkbox' onChange={() => toggleTerms()}/> <div>
                      I have read the <a href='/privacy-policy'>Privacy Policy</a> and the <a href='/terms-and-conditions'>Terms of Use</a> and I agree to them.
                    </div>
                  </div>

                  <div className='policy'>
                    <input type='checkbox' onChange={() => toggleAge()}/> <div>
                     I agree that am above 18 years of age and i understand gambling/sport trading is risky.
                    </div>
                  </div>

                  <div className='control-btns'>
                    <button className='btn white' onClick={handleTabChangePrev}>Back</button>
                    <button disabled={
                       validation.values.first_name == "" ||
                       validation.values.last_name == "" ||
                       validation.values.email == "" ||
                       validation.values.username == "" ||
                       validation.values.password == "" ||
                       validation.values.country == "" ||
                       validation.values.gender == "" ||
                       validation.values.phone_number == "" ||
                       !isTermsChecked || !isAgeChecked
                        ? true : false
                    } className='btn btn-primary' type='submit'>Create Account</button>
                  </div>

                </div>

              </form>
              </div>
          </div>

           {/* Floating WhatsApp Button */}
            <div style={floatButtonStyle}>
              <a href="https://wa.me/7032588139" target="_blank" rel="noopener noreferrer" style={whatsappButtonStyle}>
                <Iconify icon="ic:round-whatsapp" width="30px" height="30px" />
              </a>
            </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RegisterUser


RegisterUser.propTypes = {
  history: PropTypes.object,
};

import React, {useEffect, useState} from 'react'
import Iconfiy from "pages/Utility/Iconfiy"
import axios from "axios";
import Loader from 'pages/Utility/Loader'
import PropTypes from "prop-types";
import {v4 as uuidv4} from 'uuid';
import { Col, Modal, ModalBody, Row, Table, Alert, Card } from "reactstrap"
import Bankcom from 'components/Common/BankCom';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';

import AuthCode from "react-auth-code-input"
const API_URL = process.env.REACT_APP_BASE_URL


const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use((req) => {
  if(localStorage.getItem('profile')){
      req.headers.Authorization = `Bearer ${ JSON.parse(localStorage.getItem('profile')).token}`
  }
  return req;
});

const DashSide =  ({ balance, userBanks}) => {

  const [showBalance, setShowBalance] = React.useState(false);
  const [modal, setModal] = useState(false)
  const [isActive, setIsActive] = useState("")
  const [topUpModal, setTopUpModal] = useState(false)
  const [topUpAmount, setTopUpAmount] = useState(0)
  const [destinationAccount, setDestinationAccount] = useState("")
  const [amount, setAmount] = useState(parseInt(0))
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")))
  const [disabled, setDisabled] = useState(false)
  const [pinVerified, setPinVerified] = useState(false)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [pin, setPin] = useState("")
  const [pinError, setPinError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [subError, setSubError] = useState("")
  const [withdrawalErrorMessage, setWithdrawalError] = useState("")
  const [paymentStatus, setPaymentStatus] = useState('');



  const toggleBalanceVisibility = () => {
    setShowBalance((prevShowBalance) => !prevShowBalance);
  };
 



  const withdraw = async () => {
      setModal(true)
  }

  const toggle = () => {
    setModal(!modal)
  }

  const toggleTop = () => {
    setTopUpModal(!topUpModal)
  }

  const handleChange = (code) => {
      setCount(code.length)
      setPin(code)
  }

  const selectBank = (bank) => {
     setIsActive(bank.id)
     setDestinationAccount(bank.id)
  }






  const makePayment = () => {
    setLoading(true);

    FlutterwaveCheckout({
        public_key: process.env.REACT_APP_FLWPUB,
        tx_ref: `SureTrada-${uuidv4()}`,
        amount: topUpAmount,
        currency: "NGN",
        payment_options: "card, mobilemoneyghana, ussd",
        callback: function(payment) {
            console.log(payment);
            // Send AJAX verification request to backend
            verifyTransactionOnBackend(payment.transaction_id);
        },
        onclose: function(incomplete) {
            if (incomplete) {
                setPaymentStatus('failed');
                setLoading(false);
                console.log(incomplete)
            } else {
                // Wait for backend verification to update window.verified
                // The backend verification should handle the state
            }
        },
        customer: {
            email: user.data.email,
            phone_number: user.data.phone_number,
            name: `${user.data.first_name} ${user.data.last_name}`,
        },
        customizations: {
            title: "SureTrada Powered by Proeditech",
            description: "Top up suretrada wallet powered by Proeditech",
            logo: "https://edgeequitys.s3.amazonaws.com/suretrada4.png",
        },
    });
};

const verifyTransactionOnBackend = async (transactionId) => {
    let data = {
        transaction_id: transactionId,
        expectedAmount: topUpAmount,
        expectedCurrency: "NGN"
    };
    try {
        const response = await axiosApi.post(`${API_URL}/api/v1/user/topup`, data);
        if (response.data.success) {
            toast.success('🦄 Wow! Your top up was successful', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            setPaymentStatus('success');
            window.verified = true;
        } else {
            setPaymentStatus('failed');
            window.verified = false;
        }
    } catch (error) {
        setPaymentStatus('failed');
        window.verified = false;
        toast.error('😢 Transaction verification failed. Please try again.', {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    } finally {
        setLoading(false);
       window.location.reload();
    }
};

  const verifyPin = async () => {
      setLoading(true)
      let data = {
        pin: pin,
        device_token: "1234"
      }
      axiosApi.post(`${API_URL}/api/v1/user/pin/verify`, data).then((res) => {
        setLoading(false)
        setPinVerified(true)
      }).catch((error) => {
        setLoading(false)
        console.log(error.response.data.message)
        setPinError(error.response.data.message)
      })
  }



  useEffect(() => {
    if(count > 3){
      verifyPin()
    }

  }, [count])

  const sendWithdrawalrequest = () => {
    setIsLoading(true)

    let payload = {
      destination_account: destinationAccount,
     amount,
     pin
    }
   
    axiosApi.post(`${API_URL}/api/v1/user/wallet/withdraw`, payload).then((res) => {
         setIsLoading(false);
          window.location.reload(false);
          toast.success('🦄 Congratulations!, Your withdrawal is successfully', {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
    }).catch((error) => {
         setIsLoading(false);
         setWithdrawalError(error.response.message)
    })
  }


  const subscribe = () => {
    setLoading(true)
     axiosApi.post(`${API_URL}/api/v1/user/subscribe`).then(() => {
      toast.success("Your subscription has been successfully activated")
       // get logged in user seesion 
       if(JSON.parse(localStorage.getItem("profile"))){
        const newData = {
             code: user?.code,
             data: {
                 activity_token: user?.data.activity_token,
                 avatarUrl: user?.data.avatarUrl || null,
                 batch_status: user?.data.batch_status || null,
                 bio: user?.data.bio || null,
                 bvn_verified: user?.data.bvn_verified || false,
                 can_approve_transactions: user?.data.can_approve_transactions || false,
                 can_close_batch: user?.data.can_close_batch || false,
                 can_confirm_transactions: user?.data.can_confirm_transactions || false,
                 can_create: user?.data.can_create || false,
                 can_decline_transactions: user?.data.can_decline_transactions || false,
                 can_delete: user?.data.can_delete || false,
                 can_login: user?.data.can_login || false,
                 can_open_batch: user?.data.can_open_batch || false,
                 can_post_game: user?.data.can_post_game || null,
                 can_post_signal: user?.data.can_post_signal || true,
                 can_update: user?.data.can_update || false,
                 country: user?.data.country || "nigeria",
                 createdAt: user?.data.createdAt || null,
                 device_token: user?.data.device_token || "1234",
                 device_type: user?.data.device_type || "WEB",
                 email: user?.data.email || null,
                 failed_activity_token_count: user?.data.failed_activity_token_count || 0,
                 first_name: user?.data.first_name || null,
                 game_lost: user?.data.game_lost || null,
                 game_played: user?.data.game_played || null,
                 game_won: user?.data.game_won || null,
                 gender: user?.data.gender || null,
                 has_passphrase: user?.data.has_passphrase || false,
                 has_pin: user?.data.has_pin || true,
                 has_token: user?.data.has_token || false,
                 id: user?.data.id || 45,
                 is_deleted: user?.data.is_deleted || false,
                 is_first_login: user?.data.is_first_login || null,
                 is_verified: user?.data.is_verified || true,
                 last_name: user?.data.last_name || null,
                 login_count: user?.data.login_count || 180,
                 marketing_channel: user?.data.marketing_channel || "Others",
                 passphrase: user?.data.passphrase || null,
                 phone_number: user?.data.phone_number || null,
                 referral_code: user?.data.referral_code || null,
                 referral_link: user?.data.referral_link || null,
                 referred_by: user?.data.referred_by || null,
                 role: user?.data.role || 0,
                 spoo_number: user?.data.spoo_number || null,
                 spoo_tag: user?.data.spoo_tag || null,
                 status: user?.data.status || "ACTIVE",
                 subscription:  true,
                 updatedAt: user?.data.updatedAt || null,
                 username: user?.data.username || null,                  
             },
                 status: user?.status,
                 message: user?.message,
                 token: user?.token
        }
        localStorage.removeItem("profile")
        console.log(newData)
        localStorage.setItem("profile", JSON.stringify(newData));
     }
      setLoading(false)
     }).catch((error) => {
      toast.error(error.response.data.message)
      setLoading(false)
     })
  }





  return (
    <>
     <Card style={{ borderRadius: "10px", backgroundColor: "#623DCC"}}>
      <div className="text-center" style={{ paddingTop: "30px"}}>
        <h5 style={{ color: "#ffff"}}>Balance</h5>
        <div className='d-flex flex-row justify-content-center align-items-center'>
          <h2 style={{ color: "#fff", fontWeight: "bolder"}}>
             {
              showBalance ? (
              <>
              <NumberFormat
                      value={balance} 
                      className="foo"
                      displayType={'text'}
                      thousandSeparator={true}
                      renderText={(value, props) => <div {...props}>&#8358;{value}</div>}
               /> 
              </>) : (
              <>
               <span>₦{"*".repeat(balance.length - 3) + "." + '*'.repeat(2)}</span>
              </>)
             }
          </h2>
          <div style={{ marginLeft: '7px',cursor: 'pointer', color: "#fff" }} onClick={toggleBalanceVisibility}>
            <Iconfiy icon={showBalance ? "ion:eye-outline" : "ion:eye-off-outline"}/>
          </div>
        </div>
      </div>
    
    
      

      <div style={{ paddingBottom: "10px", margin: "auto", marginTop: "15px", overflowX: "auto", maxWidth: "100" }}>
        <button onClick={() => { withdraw() }} className='btn btn-default temp-contain' style={{ border: "1px solid black", backgroundColor: "#000", color: "white" }}><Iconfiy icon="solar:arrow-up-outline" angle="225" width="20px" /> Payout</button>
        {/* {user?.data.subscription ? null :  <button disabled={loading ? true : false} onClick={() => { subscribe() }} className='btn btn-default temp-contain' style={{ border: "1px solid black", backgroundColor: "#000", color: "white", marginLeft: "2px" }}><Iconfiy icon="solar:arrow-up-outline" angle="225" width="20px" /> {loading ? "Loading.." : "Subscribe"}</button>} */}
        <button onClick={() => setTopUpModal(true)} className='btn btn-default temp-contain' style={{ border: "1px solid black", backgroundColor: "#000", color: "white", marginLeft: "2px" }}><Iconfiy icon="ic:round-plus" width="13px" /> Top up</button>
    </div>


      
     </Card>
      

      <Modal  
         isOpen={modal}
        toggle={toggle} size="md" centered={true}>
        <ModalBody className="py-3 px-5">

                  <h4 className="pt-5">Withdraw</h4>
                  <p>Please enter your withdrawal details</p>
                   {pinError ?  <Alert color="danger">{pinError}</Alert>  : null}
                        
  
                  <div className='input-set'>
                    <input type='number' onChange={(e) => setAmount(e.target.value)} className='form-control mine-input' placeholder='Enter amount'/>
                    <span>Balance:</span> <span style={{ color: "green"}}>₦{balance}</span>
                  </div>
                  <br />
                   {userBanks.length > 0 ? (
                    <>
                      <p>Select your bank</p>
                      {userBanks.map((bank) => (
                        <>
                          <Bankcom account_name={bank.account_name} account_number={bank.account_number} id={bank.id} bank_name={bank.bank_name} onSelect={() => {selectBank(bank)}} isActive={isActive}  />
                        </>
                      ))}
                    </>) : (
                      <>
                       <p>You dont have any bank added, please add one <a href="/save-bank">here</a></p>
                      </>
                    )}
                
                 
                   <hr />
                   {/* <p>Enter Your Transaction Pin</p>
                   <AuthCode
                        characters={4}
                        onKeyUp={() => setCount(count + 1)}
                        className="form-control form-control-lg text-center"
                        inputStyle={{
                          width: "50px",
                          height: "calc(1.5em + 1rem + 2px)",
                          padding: ".5rem 1rem",
                          borderRadius: "8px",
                          fontSize: "1.01562rem",
                          textAlign: "center",
                          marginRight: "15px",
                          border: "1px solid #ced4da",
                          textTransform: "uppercase",
                          borderRadius: ".4rem"
                        }}
                        onChange={handleChange}
                      />
                      <span>Transaction Pin</span> {loading && (<><span style={{ color: "green", float: "right"}}>Verifying Pin...</span></>)} {pinVerified && !loading && (<><span style={{ color: "green", float: "right"}}>Verified</span></>)} {!loading && !pinVerified && (<><span style={{ color: "red", float: "right"}}>Unverified Pin</span></>)} */}
  
                  <button onClick={() => {sendWithdrawalrequest()}} className='btn btn-primary full mt-5' disabled={disabled}>{isLoading && (<Loader />)} {!isLoading && "Withdraw" }</button>
          
        </ModalBody>
    </Modal>

    <Modal  
         isOpen={topUpModal}
        toggle={toggleTop} size="md" centered={true}>
          <ModalBody className="py-3 px-5">
                  <h4 className="pt-5">Top Up Your Wallet</h4>

                  <div className='input-set'>
                    <input type='number' className='form-control mine-input' onChange={(e) => setTopUpAmount(e.target.value)} placeholder='Enter amount'/>
          
                         <span>Balance:</span> <span style={{ color: "green"}}>₦{balance}</span>

                  </div>
                  <br />
                 
                  <button className='btn btn-primary full mt-5' onClick={() => {makePayment()}}>Top Up</button>
          
        </ModalBody>
    </Modal>
    </>
  )
}

export default DashSide


DashSide.propTypes = {
  balance: PropTypes.any,
  userBanks: PropTypes.any,
  user: PropTypes.any
}


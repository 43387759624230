import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";

import NumberFormat from 'react-number-format';
import ProgressBar from "@ramonak/react-progress-bar"
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  ModalBody
} from "reactstrap";
import DashSide from './DashSide'
import Savings from "../../components/Common/Savings"
import Services from "../../components/Common/Services"
import Trans from "../../components/Common/TransactionCom"
import GeneralCard from "../../components/Common/GeneralCard"



const API_URL = process.env.REACT_APP_BASE_URL


const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use((req) => {
  if(localStorage.getItem('profile')){
      req.headers.Authorization = `Bearer ${ JSON.parse(localStorage.getItem('profile')).token}`,
      req.headers.Accept = 'application/json'
  }
  return req;
});




const Dashboard = props => {

  const [userBanks, setUserBanks] = useState([])
  const [balance, setBalance] = useState(0)
  const [transactions, setTransactions] = useState([])
  const [modal, setModal] = useState(false)
  const [transaction, setTransaction] = useState()
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")))

  const getUserWallet = async () => {
    try {
      const response = await axiosApi.get(`${API_URL}/api/v1/user/naira-wallet`);
      setBalance(response.data.balance);
    } catch (error) {
      console.log(error.response?.data?.message);
      if (error.response?.data?.message === "Token is expired or invalid.") {
        localStorage.removeItem("profile");
      }
    }
  };
  
  
  const getUserBanks = async () => {
    axiosApi.get(`${API_URL}/api/v1/user/banks`).then((res) => {
      setUserBanks(res.data.data)
    }).catch((error) => {
  
    })
  }

  const getUserTransactions = () => {
    let lim = 5
    axiosApi.get(`${API_URL}/api/v1/user/limit-transactions`).then((res) => {
      setTransactions(res.data.data)
    }).catch((error) => {
  
    })
  }
  
  
  const toggle = () => {
    setShow(!show)
  }
  
  useEffect(() => {
    getUserWallet()
    getUserBanks()
    getUserTransactions()
  }, [balance])

  const transactionDetails = async (trans) => {
    setTransaction(trans)
    setModal(true)
}

const toggleTrans = () => {
  setModal(!modal)
}
  







  return (
    <React.Fragment>
      
      <div className="page-content">
        <MetaTags>
          <title>SureTrada | Dashboard </title>
        </MetaTags>
        <Container style={{ justifyContent: "center"}}>
           <Row>
             <Col md="10">
               <Row>
               <Col md="5">
                 <Row>
                    <Col md="12">
                        <DashSide balance={balance} userBanks={userBanks} user={user} />
                        <span>&#8358;2,000 monthly subscription(auto-trada/signals)</span>
                    </Col>

                    <Col md="12" style={{ backgroundColor: "white", paddingTop: "20px"}}>
                      <h3>Our services</h3> 
                      <hr />
                      <div className="mobile">
                          <Services id={3} title="Punters hub" details="Buy and sell games" link="/punters-hub"  />
                          <Services id={8} title="Auto-trada" details="We trade, you earn" link="/auto-trada"  />
                          <Services id={4} title="Over 4.5 signal" details="Virtual signal" link="/signal-4"  />
                          <Services id={5} title="Stake calculator" details="calculate stake amount based on odd" link="/stake-calculator"  />
                          <Services id={6} title="Over 2.5 calculator" details="Always play Over 2.5" link="/overtwofivecalculator"  />
                          <Services id={7} title="How to" details="Learn how to use our tools" link="/how-to"  />
                      </div>
                    
                     <hr />

                     <Services id={1} title="My profile" details="View your account details." link="/profile"  />
                     <Services id={2} title="My Games" details="See all your games." link="/my-games"  />

                    </Col>
                 </Row>
             
             </Col>
                   <Col md="6">
                     <div className='plan-head'>
                        <h4 style={{ marginBottom: '20px' }}>Transactions</h4>
                     </div>

                     {transactions.length > 0 ? (
                        transactions.map((tran) => (
                          <>
                          <Trans type={tran.type} status={tran.status} date={tran.createdAt} amount={tran.amount} onclick={() => transactionDetails(tran)} />
                         </>
                        ))
                      
                     ) : (
                        <>
                          <p>You have no transaction at the moment..</p>
                        </>
                     )}
                     {transactions.length > 0 && (
                      <a href="/transactions"><p style={{ color: "#6210CC"}}>View All Transactions</p></a>
                     )}
                        

                    
          
                 </Col>
               </Row>
             </Col>
           </Row>
        </Container>
      </div>

      <Modal   isOpen={modal} toggle={toggleTrans}  size="md" centered={true} >
          <h4 style={{ textAlign: "center", paddingTop: "10px"}}>Transaction Details</h4>
           <ModalBody className="py-3 px-5">
            <Card style={{ padding: "10px"}}>
            {transaction?.type === "WITHDRAWAL" ? (
            <h4 style={{ textAlign: "center"}}>
                <NumberFormat
                      value={transaction.amount} 
                      className="foo"
                      displayType={'text'}
                      thousandSeparator={true}
                      renderText={(value, props) => <div {...props}>+ &#8358;{value}</div>}
               /> 
            </h4>
            ) : transaction?.type === "FUNDING" ? (
              <h4 style={{ textAlign: "center"}}>
              <NumberFormat
                    value={transaction.amount} 
                    className="foo"
                    displayType={'text'}
                    thousandSeparator={true}
                    renderText={(value, props) => <div {...props}>+ &#8358;{value}</div>}
             /> 
          </h4>
            ) : transaction?.type === "SPOOWEALTH" ? (<h4>- ₦{transaction?.amount}</h4>) : <h4>₦{transaction?.amount}</h4>}
            <p style={transaction?.status === "SUCCESS" ? {color: "green", textAlign: "center"} : transaction?.status === "PENDING" ? {color: "orange", textAlign: "center"} : null}>{transaction?.status === "SUCCESS" ? "Succesfull" : transaction?.status === "PENDING" ? "Pending" : null}</p>
            <div>
              <span>Amount</span>
              <span style={{ float: "right"}}>
              <NumberFormat
                    value={transaction?.amount} 
                    className="foo"
                    displayType={'text'}
                    thousandSeparator={true}
                    renderText={(value, props) => <div {...props}>+ &#8358;{value}</div>}
             /> 
              </span> 
              <br />

              <span>Transaction Type</span>
              <span style={{ float: "right"}}>{transaction?.type}</span> <br />

              <span>Narration</span>
              <span style={{ float: "right"}}>{transaction?.narration}</span> <br />

              <span>Payment Method</span>
              <span style={{ float: "right"}}>Balance</span> <br />
              
            </div>
            </Card>
            </ModalBody>
     </Modal>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  userDetails: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default Dashboard

import React from 'react';
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import PropTypes from "prop-types";
import { useEffect } from "react";
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import Iconify from '../../pages/Utility/Iconfiy'


const AppLayout = (props) => {

    const floatButtonStyle = {
        position: 'fixed',
        bottom: '120px',
        right: '20px',
        zIndex: 1000,
      };
      
      const whatsappButtonStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50px',
        height: '50px',
        backgroundColor: '#25D366',
        borderRadius: '50%',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        color: '#fff',
      };
    
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                
                <div className="main-content">{props.children}</div>
                 {/* Floating WhatsApp Button */}
                <div style={floatButtonStyle}>
                    <a href="https://wa.me/7032588139" target="_blank" rel="noopener noreferrer" style={whatsappButtonStyle}>
                    <Iconify icon="ic:round-whatsapp" width="30px" height="30px" />
                    </a>
                </div>
                <Footer />

            </div>
      {/* {showRightSidebar ? <RightSidebar /> : null}   */}
        </>
    );
}

AppLayout.propTypes = {
    children: PropTypes.object,
}


export default withRouter(AppLayout);
import React from 'react'
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import Iconfiy from 'pages/Utility/Iconfiy'
import { toast } from 'react-toastify';


const Profilecom = ({ user, refLength }) => {

  const shortenString = (str, maxLength) => {
    if (str.length <= maxLength) {
        return str;
    } else {
        return str.substring(0, maxLength) + '...';
    }
};



  const copyToClipboard = () => {
    const referralLink = user.data.referral_link;
    navigator.clipboard.writeText(referralLink)
        .then(() => {
            toast.success('Referral link copied to clipboard:', referralLink)
            // You can also show a success message here if needed
        })
        .catch(err => {
            console.error('Failed to copy referral link to clipboard:', err);
            // You can also show an error message here if needed
        });
};


  return (
      <div className='individual-plans' >
        <div className='amount'>
          <p className='title start'>Fullname</p>
          <p>{user.data.first_name + " " + user.data.last_name} </p>
        </div>
        <div className='duration'>
          <p className='title'>Country</p>
          <p>{user.data.country}</p>
        </div>
        <div className='status'>
          <p className='title'>Email</p>
          <p>{user.data.email}</p>
        </div>

        <div className='status'>
          <p className='title'>Phone Number</p>
          <p>{user.data.phone_number}</p>
        </div>

        <div className='status'>
          <p className='title'>Gender</p>
          <p>{user.data.gender}</p>
        </div>

        <div className='status'>
          <p className='title'>Joined date</p>
          <p>{user.data.createdAt}</p>
        </div>

        <div className='status'>
          <p className='title'>Username</p>
          <p>{user.data.username}</p>
        </div>

        <div className='status'>
          <p className='title'>Referral Code</p>
          <p>{user.data.referral_code}</p>
        </div>

        <div className='status'>
          <p className='title'>Total Referrals</p>
          <p>{refLength}</p>
        </div>

       

        <div className='status'>
          <p className='title'>Referral Link</p> <br />
        </div>

        <div className=''>
        <p>{shortenString(user.data.referral_link, 10).toLowerCase()}</p>
           <span style={{ paddingLeft: "10px"}} onClick={copyToClipboard}>
              <Iconfiy icon="ph:copy"/>
           </span>
        </div>

          
      </div>
   
  )
}

Profilecom.propTypes = {
  user: PropTypes.any,
  refLength: PropTypes.number,
};

export default Profilecom

// TeamSelector.js
import React from 'react';
import PropTypes from 'prop-types';
import { teams } from './team';


const TeamSelector = ({ selectedTeam, onSelectTeam }) => {
  return (
    <div className="team-selector">
      <h2 className="selector-title">Select Team</h2>
      <div className="team-list">
        {teams.map((team) => (
          <button
            key={team.id}
            className={`team-button ${selectedTeam === team.id ? 'selected' : ''}`}
            onClick={() => onSelectTeam(team.id)}
          >
            <img src={team.logo} alt={team.name} className="team-logo" />
            <span className="team-name">{team.shortName}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

TeamSelector.propTypes = {
  selectedTeam: PropTypes.number,
  onSelectTeam: PropTypes.func.isRequired,
};

export default TeamSelector;
import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useMemo } from "react";
import MetaTags from "react-meta-tags";
import { ToastContainer, toast } from 'react-toastify';
import ReportCard from "components/Common/ReportCard"
import 'moment-timezone';
import NumberFormat from 'react-number-format';
import Loader from "pages/Utility/Loader";
import YouTubeVideo from '../../components/Common/YoutubeCom';
import GeneralCard from "../../components/Common/GeneralCard"





import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Label,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Button
} from "reactstrap";


const API_URL = process.env.REACT_APP_BASE_URL


const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use((req) => {
  if(localStorage.getItem('profile')){
      req.headers.Authorization = `Bearer ${ JSON.parse(localStorage.getItem('profile')).token}`,
      req.headers.Accept = 'application/json'
  }
  return req;
});



const HowTo = props => {

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Suretrada | Learn how to use our platform</title>
        </MetaTags>
        <Container>
          <Row>
            <Col md="12">
                <h3>Learn everything about SureTrada Tools</h3>
                  <Row>
                      <Col md="6">
                        <h4>Sign up guide</h4>
                       <YouTubeVideo videoUrl="https://www.youtube.com/watch?v=ZV6UEo8gmH0" />
                     </Col>


                     <Col md="6">
                       <h4>Introduction</h4>
                       <YouTubeVideo videoUrl="https://www.youtube.com/watch?v=cmBX0cwARK8" />
                     </Col>

                     <Col md="6">
                       <h4>Stake calculator</h4>
                       <YouTubeVideo videoUrl="https://www.youtube.com/watch?v=JTKOwh69GIM" />
                     </Col>

                     <Col md="6">
                       <h4>Over 2.5 Strategy 1</h4>
                       <YouTubeVideo videoUrl="https://www.youtube.com/watch?v=W_S5w1dJ_D8" />
                     </Col>

                     <Col md="6">
                        <h4>Over 2.5 Strategy 2</h4>
                       <YouTubeVideo videoUrl="https://www.youtube.com/watch?v=95HzFE844s8" />
                     </Col>

                     <Col md="6">
                        <h4>4k daily tips</h4>
                       <YouTubeVideo videoUrl="https://www.youtube.com/watch?v=zGvDNZoznzM" />
                     </Col>

                     <Col md="6">
                        <h4>Over 4.5 signal</h4>
                       <YouTubeVideo videoUrl="https://www.youtube.com/watch?v=7zgZySzcEp4" />
                     </Col>

                     <Col md="6">
                        <h4>Punters Hub</h4>
                       <YouTubeVideo videoUrl="  https://www.youtube.com/watch?v=_aZPivGYkI0" />
                     </Col>


                   

                  </Row>
            
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default HowTo;

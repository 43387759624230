import React from 'react'
import PropTypes from 'prop-types';
import Iconfiy from 'pages/Utility/Iconfiy'
import { Link } from "react-router-dom";


const MessageCard = ({title, details, timestamp}) => {
  return (
    <div className='temp-contain d-flex flex-row align-items-center' >
      <div className='spooIcon'>
        {
        title == "Start" ?   <Iconfiy icon="streamline:startup-solid"  color="green"/> :
        title == "Pre-signal" ?   <Iconfiy icon="game-icons:traffic-lights-ready-to-go"  color="orange"/> :
        title == "Boom" ?   <Iconfiy icon="icon-park-outline:success"  color="#5719D0"/> :
        title == "Stop" ?   <Iconfiy icon="mdi:stop-outline"  color="red"/> :
        <Iconfiy icon="majesticons:logout"  color="#000"/>
        }
       
      </div>

      <div style={{ flexGrow: "1" }}>
        <h4>{title}</h4>
        <p>{details}</p>
      </div>

      <div>
          
         <p >
         <h4>{timestamp}</h4>
             <Iconfiy  icon="material-symbols:circle-outline" />
         </p>
      </div>

   
    </div>
  )
}

export default MessageCard


MessageCard.propTypes = {
  title: PropTypes.any,
  details: PropTypes.any,
  id: PropTypes.any,
  timestamp: PropTypes.any,
  stamp:PropTypes.any,
  link: PropTypes.any
}


  
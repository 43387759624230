import React from 'react';
import YouTube from 'react-youtube';
import PropTypes from 'prop-types';

const YouTubeVideo = ({ videoUrl }) => {
  // Extract the YouTube video ID from the URL
  const videoId = videoUrl.split('v=')[1];

  // Options for the YouTube player
  const opts = {
      height: '400',
      width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
  
     <div className='card' style={{ borderRadius: "20px"}}>
         <YouTube videoId={videoId} opts={opts} />
     </div>
    

  );
};

export default YouTubeVideo;

YouTubeVideo.propTypes = {
    videoUrl: PropTypes.any
  };
  
import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import { ToastContainer, toast } from 'react-toastify';
import MessageCard from "components/Common/MessageCard";
import 'moment-timezone';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import Loader from "pages/Utility/Loader";
import Iconfiy from 'pages/Utility/Iconfiy'
import  shortenRelativeTime from "pages/Utility/time"
import  {groupA, groupB, groupC, groupCTwo}  from "../../common/data/stakelist"



import firebase from "firebase/compat/app";
import "firebase/compat/database";

// // Initialize Firebase
// const firebaseConfig = {
//   // Your Firebase config here
// };

// if (!firebase.apps.length) {
//   firebase.initializeApp(firebaseConfig);
// }












import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Label,
  CardBody,
  Input,
  Modal,
  Table,
  TableHeader,
  ModalHeader,
  ModalBody,
  Form,
  Button,
  Alert
} from "reactstrap";


const API_URL = process.env.REACT_APP_BASE_URL


const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use((req) => {
  if(localStorage.getItem('profile')){
      req.headers.Authorization = `Bearer ${ JSON.parse(localStorage.getItem('profile')).token}`,
      req.headers.Accept = 'application/json'
  }
  return req;
});


const groupAComponent = () => {
  return (
      <>
        <div style={{ padding: "20px"}}>
          <h5>Group A</h5>
          <p>Minimum capital required is ₦120,000</p>
          <p>Minimum target to be won per ₦500</p>
         
           <Table>
                <thead>
                     <th>SN</th>
                     <th>Stake Amount</th>
                </thead>
                <tbody>
                   {groupA.map((name) => (
                    <>
                      <tr>
                        <td>{name.name}</td>
                        <td>
                          <NumberFormat
                              value={Math.round(name.stake)}
                              className="foo"
                              displayType={'text'}
                              thousandSeparator={true}
                              renderText={(value, props) => <div {...props}>&#8358;{value}</div>}
                              /> 
                          </td>
                       </tr>   
                    </>
                   ))}
                </tbody>
           </Table>
        </div>
      </>
  )
}


const groupBComponent = () => {
  return (
      <>
        <div style={{ padding: "20px"}}>
          <h5>Group B</h5>
          <p>Minimum capital required is ₦48,000</p>
          <p>Minimum target to be won per ₦500</p>
         
           <Table>
                <thead>
                     <th>SN</th>
                     <th>Stake Amount</th>
                </thead>
                <tbody>
                   {groupB.map((name) => (
                    <>
                      <tr>
                        <td>{name.name}</td>
                        <td>
                          <NumberFormat
                              value={Math.round(name.stake)}
                              className="foo"
                              displayType={'text'}
                              thousandSeparator={true}
                              renderText={(value, props) => <div {...props}>&#8358;{value}</div>}
                              /> 
                          </td>
                       </tr>   
                    </>
                   ))}
                </tbody>
           </Table>
        </div>
      </>
  )
}






const groupCComponent = () => {
  return (
      <>
        <div style={{ padding: "20px"}}>
          <h5>Group C</h5>
          <p>Minimum capital required is ₦20,000</p>
          <p>Minimum target to be won per ₦500</p>
         
           <Table>
                <thead>
                     <th>SN</th>
                     <th>Stake Amount</th>
                </thead>
                <tbody>
                   {groupC.map((name) => (
                    <>
                      <tr>
                        <td>{name.name}</td>
                        <td>
                          <NumberFormat
                              value={Math.round(name.stake)}
                              className="foo"
                              displayType={'text'}
                              thousandSeparator={true}
                              renderText={(value, props) => <div {...props}>&#8358;{value}</div>}
                              /> 
                          </td>
                       </tr>   
                    </>
                   ))}
                </tbody>
           </Table>
        </div>
      </>
  )
}

const groupCTwoComponent = () => {
  return (
      <>
        <div style={{ padding: "20px"}}>
          <h5>Group C Sub</h5>
          <p>Minimum capital required is ₦5,000 - ₦20,000</p>
          <p>Minimum target to be won per ₦200</p>
         
           <Table>
                <thead>
                     <th>SN</th>
                     <th>Stake Amount</th>
                </thead>
                <tbody>
                   {groupCTwo.map((name) => (
                    <>
                      <tr>
                        <td>{name.name}</td>
                        <td>
                           <NumberFormat
                              value={Math.round(name.stake)}
                              className="foo"
                              displayType={'text'}
                              thousandSeparator={true}
                              renderText={(value, props) => <div {...props}>&#8358;{value}</div>}
                              /> 
                          </td>
                       </tr>   
                    </>
                   ))}
                </tbody>
           </Table>
        </div>
      </>
  )
}

const rulesComponent = () => {
  return (
      <>
        <div  style={{ padding: "20px"}}>
          <h5>GENERAL GUILDLINES</h5>
          <p>Kindy read the following rules for effective trading with the signals</p>
           <ol>
              <li>Make sure your network is stable before trading and free from distractions, @ the bearest minimuma MIFI connection or DO NOT DISTURB(DND) should be activated</li>
              <li style={{ paddingTop: "5px"}}>Do not trade after a win or after a network glitch that led to a win declare a stop loss if you feel obligated to  follow the loss you will either win or lose you will most probably win when there is an infinite access to Money.</li>
              <li style={{ paddingTop: "5px"}}>For every preceding trade stake as early as possible, so even if there is an error you have enough time to exit reopen & stake properly</li>
              <li style={{ paddingTop: "5px"}}>The emergency WORD for PRESIGNAL INFORMATION to prepare you to be ready for a signal is &#34;WHO IS ACTIVE GET READY  TO START TRADING&#34;, at this point you should have accessed your sports account which should be  always funded @ all times because SIGNALS comes at any time of the day.</li>
              <li style={{ paddingTop: "5px"}}>It is important to get used to the sport App interface for a swifter activity a loss in time is a loss in cash as the Presignal comes you should be in the right Webpage in your app and ready to stake.</li>
              <li style={{ paddingTop: "5px"}}>Once again please be void of distractions</li>
              <li style={{ paddingTop: "5px"}}>DISCLAIMER; The group is for signal & informational purposes based on SPORTS TRADING and not a Financial advisors platform. Please consult with a professional Financial advisor before making any trading decision.</li>
           </ol>
        
        </div>
      </>
  )
}

const categorizationsComp = () => {
  return (
      <>
        <div style={{ padding: "20px"}}>
          <h5>CATEGORIZATION</h5>
            <p>CATEGORIZATION is based on the minimum cash you have designated  to the sports account which is your &quo;STOP LOSS&quot; to trade with, which should be in the sports account at all time keeping in mind that the time required to place a trade is lesser than 1 minute 30 seconds. 
            The higher the minimum cash the higher the available signal for you in 24 hours.</p>
         
           <p>
            <span style={{ fontWeight: "bold"}}>CATEGORY A</span> <br />
            With a minimum sports account balance  of <span style={{ fontWeight: "bold"}}>&#8358;120,000</span> this category have the <span style={{ fontWeight: "bold"}}>MOST</span> signal in 24 hours and is guaranteed <span style={{ fontWeight: "bold"}}>SAFE</span> from analysis except there is an <span style={{ fontWeight: "bold"}}>ANOMALY</span>.
           </p>
        
           <p>
            <span style={{ fontWeight: "bold"}}>CATEGORY B</span> <br />
            With a minimum sports account balance  of <span style={{ fontWeight: "bold"}}>&#8358;48,000</span> this category have the <span style={{ fontWeight: "bold"}}>MOST</span> signal in 24 hours and is guaranteed <span style={{ fontWeight: "bold"}}>SAFER</span> from analysis except there is an <span style={{ fontWeight: "bold"}}>ANOMALY</span>.
           </p>

           <p>
            <span style={{ fontWeight: "bold"}}>CATEGORY C</span> <br />
            With a minimum sports account balance  of <span style={{ fontWeight: "bold"}}>&#8358;20,000</span> this category have the <span style={{ fontWeight: "bold"}}>LEAST</span> signal in 24 hours and is guaranteed <span style={{ fontWeight: "bold"}}>SAFEST</span> from analysis except there is an <span style={{ fontWeight: "bold"}}>ANOMALY</span>.
           </p>

           <p>
           NB; There is room for the multiplier effect the major difference between the categories is the number of signals available to each of them in 24 hours and the cash available for you to trade, you can decide to play the safest category C with more money all you need is to have the multiplier value as your new account balance.
           </p>

           <p>
           Example if I choose  the safest category which is that with less signals I may get just 3 signals mean while other categories may get more than 5 or more than 7, what we mean by the multiplier effect will be if I want to X5 the CATEGORY C I will need 100,000 as my new account balance so i get X5 of the safest category take into consideration that the risk here is minimal and you may not earn as much as the upper CATEGORY
           </p>
        
        </div>
      </>
  )
}




const Dashboard = props => {
  const [group, setGroup] = useState("groupctwo")
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [details, setDetails] = useState("")
  const [title, setTitle] = useState("")
  const [targetGroup, setTargetGroup] = useState("")
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")))


  useEffect(() => {
    // Create a reference to the Firestore collection
    const collectionRef = firebase.firestore().collection('signal');

    // Subscribe to real-time updates and order by timestamp
    const unsubscribe = collectionRef.orderBy('timestamp').onSnapshot((snapshot) => {
      const newData = snapshot.docs.map((doc) => {
        const docData = doc.data();
        const timestamp = docData.timestamp?.toDate(); // Convert Firestore timestamp to JS Date
        const formattedTimestamp = timestamp ? shortenRelativeTime(timestamp) : 'N/A'; // Format the date with moment.js
        return { id: doc.id, ...docData, formattedTimestamp };
      });
      setData(newData);
    });

    // Cleanup subscription on component unmount
    return () => unsubscribe();
  }, []);


  const handleSubmit = async () => {
    try {
      const collectionRef = firebase.firestore().collection('signal');
      // Add new document with message and current timestamp
      const messagePayload = {
        details: details,
        group: targetGroup,
        status: "Active",
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        created_at: new Date(),
        title: title
      }
      await collectionRef.add(messagePayload);
      toast.success("Message sent successfully")
      toggle()
    } catch (error) {
      toast.error(error)
    }
  };



  const toggle = () => {
      setModal(!modal)
  }

  const deleteAllDocuments = async (collectionName) => {
    try {
      // Get a reference to the collection
      const collectionRef = firebase.firestore().collection(collectionName);
      
      // Get all documents in the collection
      const querySnapshot = await collectionRef.get();
      
      // Delete each document
      querySnapshot.forEach(async (doc) => {
        await doc.ref.delete();
      });
      toast.success('All documents successfully deleted!')
    } catch (error) {
      toast.error('Error deleting documents: ', error)
    }
  };
  

  const handleDeleteAll = async () => {
    const collectionName = 'signal';
    await deleteAllDocuments(collectionName);
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>SureTrada | Signal Over 4.5</title>
        </MetaTags>

        <>
          <Container>
           <Row>
             <Col md="5" style={{ backgroundColor: "white", borderRadius: "20px"}}>
                  <div style={{ marginTop: "10px"}}>
                  
                    {user.data.can_post_signal ? (
                      <>
                       <Button
                          color="default"
                          className="font-16 btn-block btn btn-default"
                          onClick={() => toggle()}
                          style={{
                            border: "1px solid #5719D0",
                            marginLeft: "5px"
                          }}
                        >
                          
                          Post Signal
                        </Button>
                             
                        <Button
                          color="default"
                          className="font-16 btn-block btn btn-default"
                          onClick={handleDeleteAll}
                          style={{
                            border: "1px solid #5719D0",
                            marginLeft: "5px"
                          }}
                        >
                          
                          Clear Signals
                        </Button>
                      </>
                    ) : (
                      <>
                       <Button
                          color="default"
                          className=" btn-block btn btn-default"
                          
                          style={{
                            border: "1px solid #5719D0"
                          }}
                        >
                         
                         <select className="form-control"    onChange={(e) => setGroup(e.target.value)}>
                                <option>Select Category</option>
                                <option value="groupa">Category A</option>
                                <option value="groupb">Category B </option>
                                <option value="groupc">Category C</option>
                                <option value="groupc-sub">Category C Sub</option>
                          </select>
                        </Button>

                        <Button
                          color="default"
                          className="font-16 btn-block btn btn-default"
                          onClick={() => setGroup("rules")}
                          style={{
                            border: "1px solid #5719D0",
                            marginLeft: "5px"
                          }}
                        >
                   
                          Rules
                        </Button>

                        <Button
                          color="default"
                          className="font-16 btn-block btn btn-default"
                          onClick={() => setGroup("categorizations")}
                          style={{
                            border: "1px solid #5719D0",
                            marginLeft: "5px"
                          }}
                        >
                          
                          Categorizations
                        </Button>
                      </>
                    )}
                     

                 
                      </div>

                    <div style={{ marginTop:"20px"}}>
                      {data.length === 0 && (
                         <div className="waiting-signal">
                          <p style={{ color: "#5719D0", fontWeight: "bolder", textAlign: "center"}}>Waiting for signal...</p>
                         </div>
                      )}
                         {data.map(item => (
                          <>
                              <MessageCard key={item.id} title={item.title} details={item.details}  timestamp={item.formattedTimestamp}/>
                          </>
                       

                  ))}
                
                    </div>
            
               </Col>

               <Col md="6" style={{ backgroundColor: "white", borderRadius: "10px", marginTop: "20px", marginLeft: "5px"}}>
                   {
                    group === "groupa" ?  groupAComponent() :
                    group === "groupb" ? groupBComponent() :
                    group === "groupc" ? groupCComponent() :
                    group === "groupc-sub" ? groupCTwoComponent() :
                    group === "rules" ? rulesComponent() :
                    group === "categorizations" ? categorizationsComp() :
                    null
                   }

           
                         
               </Col>
           </Row>

                <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                    return false;
                                }}
                                >
                                <Row form>
                                <Col xs={12}>
                                    <Row>
                                       <Col md="6">
                                          <div className="mb-3">
                                            <Label className="form-label">Targeted Group</Label>
                                              <select className="form-control"    onChange={(e) => setTargetGroup(e.target.value)}>
                                                    <option>Select Group</option>
                                                    <option value="A">Category A</option>
                                                    <option value="B">Category B</option>
                                                    <option value="C">Category C</option>
                                                    <option value="C Sub">Category C sub</option>
                                              </select>
                                           </div>
                                       </Col>

                                       <Col md="6">
                                          <div className="mb-3">
                                            <Label className="form-label">Status</Label>
                                            <select className="form-control"    onChange={(e) => setTitle(e.target.value)}>
                                                  <option>Select status</option>
                                                  <option value="Pre-signal">Pre-signal</option>
                                                  <option value="Start">Start Trading</option>
                                                  <option value="Stop">Stop Trading</option>
                                                  <option value="Boom">Boom</option>
                                            </select>
                                           </div>
                                       </Col>
                                    </Row>

                                    <Row>
                                       <Col md="12">
                                          <div className="mb-3">
                                            <Label className="form-label">Signal Message</Label>
                                                  <select className="form-control"   onChange={(e) => setDetails(e.target.value)}>
                                                  <option>Select Message</option>
                                                  <option value={'Category' + " " + targetGroup + " " +  'ready to trade'}>Category {targetGroup} get ready to start trading</option>
                                                  <option value={'Category' + " " + targetGroup + " " + 'start trading'}>Category {targetGroup} you can start trading now</option>
                                                  <option value={'All category can stop trading now'}>All category can stop trading now</option>
                                                  <option value={'Boom, game played'}>Boom, the game has played</option>
                                            </select>
                                           </div>
                                       </Col>
                                      

                                  
                                    </Row>
  
                                 </Col>
                                </Row>
                                <Row>
                                <Col>
                                    <div className="text-end">

                                    <button
                                        type="submit"
                                      
                                        className="btn btn-default"
                                        style={{ backgroundColor: "#5719D0", color: "#fff"}}
                                    >
                                        Post signal
                                    </button>
                                    </div>
                                </Col>
                                </Row>
                            </Form>
                            </ModalBody>
                        </Modal>
        </Container>
        </>
       
        
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  userDetails: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default Dashboard;

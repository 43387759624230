// teams.js
export const teams = [
    { id: 1, name: "Arsenal", shortName: "ARS", logo: "/logos/arsenal.png" },
    { id: 2, name: "Aston Villa", shortName: "AVL", logo: "/logos/aston-villa.png" },
    { id: 3, name: "Bournemouth", shortName: "BOU", logo: "/logos/bournemouth.png" },
    { id: 4, name: "Brentford", shortName: "BRE", logo: "/logos/brentford.png" },
    { id: 5, name: "Brighton", shortName: "BHA", logo: "/logos/brighton.png" },
    { id: 6, name: "Burnley", shortName: "BUR", logo: "/logos/burnley.png" },
    { id: 7, name: "Chelsea", shortName: "CHE", logo: "/logos/chelsea.png" },
    { id: 8, name: "Crystal Palace", shortName: "CRY", logo: "/logos/crystal-palace.png" },
    { id: 9, name: "Everton", shortName: "EVE", logo: "/logos/everton.png" },
    { id: 10, name: "Fulham", shortName: "FUL", logo: "/logos/fulham.png" },
    { id: 11, name: "Liverpool", shortName: "LIV", logo: "/logos/liverpool.png" },
    { id: 12, name: "Luton", shortName: "LUT", logo: "/logos/luton.png" },
    { id: 13, name: "Manchester City", shortName: "MCI", logo: "/logos/manchester-city.png" },
    { id: 14, name: "Manchester United", shortName: "MUN", logo: "/logos/manchester-united.png" },
    { id: 15, name: "Newcastle", shortName: "NEW", logo: "/logos/newcastle.png" },
    { id: 16, name: "Nottingham Forest", shortName: "NFO", logo: "/logos/nottingham-forest.png" },
    { id: 17, name: "Sheffield United", shortName: "SHU", logo: "/logos/sheffield-united.png" },
    { id: 18, name: "Tottenham", shortName: "TOT", logo: "/logos/tottenham.png" },
    { id: 19, name: "West Ham", shortName: "WHU", logo: "/logos/west-ham.png" },
    { id: 20, name: "Wolverhampton", shortName: "WOL", logo: "/logos/wolverhampton.png" }
  ];
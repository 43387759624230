// PlatformSelector.js
import React from 'react';
import PropTypes from 'prop-types';


const platforms = [
  { id: 1, name: 'Platform A', logo: '/path-to-logo-a.png' },
  { id: 2, name: 'Platform B', logo: '/path-to-logo-b.png' },
  { id: 3, name: 'Platform C', logo: '/path-to-logo-c.png' },
];

const PlatformSelector = ({ selectedPlatform, onSelectPlatform }) => {
  return (
    <div className="platform-selector">
      <h2 className="selector-title">Select Platform</h2>
      <div className="platform-list">
        {platforms.map((platform) => (
          <button
            key={platform.id}
            className={`platform-button ${selectedPlatform === platform.id ? 'selected' : ''}`}
            onClick={() => onSelectPlatform(platform.id)}
          >
            <img src={platform.logo} alt={platform.name} className="platform-logo" />
            <span className="platform-name">{platform.name}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

PlatformSelector.propTypes = {
  selectedPlatform: PropTypes.number,
  onSelectPlatform: PropTypes.func.isRequired,
};

export default PlatformSelector;
import React from 'react'
import PropTypes from 'prop-types';
import Iconfiy from 'pages/Utility/Iconfiy'





const RefCard = ({text, details, status, date}) => {
  return (
    <div className='temp-contain d-flex flex-row align-items-center' onClick={onclick}>
      <div className='spooIcon'>
        
      </div>

      <div style={{ flexGrow: "1" }}>
        <h4>{text}</h4>
        <p>{date}</p>
      </div>

      <div>
         <p style={status  ? {color: "green"} : !status ? {color: "orange"} : null}>{status ? "Subscribed" : "Not Subscribed"}</p>
      </div>
    </div>

  )
}

export default RefCard


RefCard.propTypes = {
    text: PropTypes.any,
    details: PropTypes.any,
    status: PropTypes.any,
    date: PropTypes.any,
}


  
// LeagueSelector.js
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';


const LeagueSelector = ({ selectedLeague, onSelectLeague }) => {
  const [leagues, setLeagues] = useState([]);

  useEffect(() => {
    // In a real application, you would fetch this data from an API
    const fetchLeagues = () => {
      const currentDate = new Date();
      const leaguesList = Array.from({ length: 10 }, (_, i) => {
        const date = new Date(currentDate);
        date.setDate(date.getDate() - i);
        return {
          id: i + 1,
          name: `Leag-${date.toISOString().split('T')[0]}`,
        };
      });
      setLeagues(leaguesList);
    };

    fetchLeagues();
  }, []);

  return (
    <div className="league-selector">
      <h2 className="selector-title">Select League</h2>
      <div className="league-list">
        {leagues.map((league) => (
          <button
            key={league.id}
            className={`league-button ${selectedLeague === league.id ? 'selected' : ''}`}
            onClick={() => onSelectLeague(league.id)}
          >
            {league.name}
          </button>
        ))}
      </div>
    </div>
  );
};

LeagueSelector.propTypes = {
  selectedLeague: PropTypes.number,
  onSelectLeague: PropTypes.func.isRequired,
};

export default LeagueSelector;
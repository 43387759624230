import React from "react"
import { Redirect } from "react-router-dom"





import Login from "../pages/Authentication/Login"
import Dashboard from "../pages/Dashboard/index"
import SignalGroupA from "../pages/Dashboard/signal_group_a"
import OverTwoFiveCalculator from "../pages/Dashboard/overTwoFiveCal"
import SubscriptionPage from "../pages/Authentication/Waiting"
import RegisterPage from "../pages/Authentication/RegisterUser"
import Transactions from "../pages/Transactions/transactions"
import LoginUser from "pages/Authentication/LoginUser"
import EmailVerify from "pages/Authentication/EmailVerify";
import UserForgetPassword from "pages/Authentication/UserForgetPassword";
import CreatePin from "pages/Authentication/CreatePin";
import SaveBankDetails from "pages/Authentication/SaveBankDetails";
import ChangePassword from "pages/Authentication/ResetPassword";
import Profile from "../pages/Users/Profile"
import StakeCalculator from "../pages/Dashboard/stakeCalculator"
import PrivacyPolicy from "../pages/Authentication/privacy"
import TermsAndConditions from "../pages/Authentication/termsAndConditions"
import HowTo from "../pages/Dashboard/how-to"
import PuntersHub from "../pages/Dashboard/puntershub"
import OddAndEven from "pages/Dashboard/oddAndEven"
import ReferralLink from "../pages/Authentication/ReferralLink"
import MyGames from "../pages/Users/MyGames"
import Punters from "../pages/Users/Punters"
import AutoTrada from "../pages/Dashboard/AutoTrada"
import Trada from "../pages/Dashboard/Pot"
import Analyst from "../pages/Dashboard/Analyst"




const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/overtwofivecalculator", component: OverTwoFiveCalculator},
  { path: "/signal-4", component: SignalGroupA},
  { path: "/stake-calculator", component: StakeCalculator},
  { path: "/transactions", component: Transactions},
  { path: "/profile", component: Profile},
  { path: "/how-to", component: HowTo},
  { path: "/punters-hub", component: PuntersHub},
  { path: "/virtual-analyst", component: Analyst},
  { path: "/odd-and-even", component: OddAndEven},
  { path: "/my-games", component: MyGames},
  { path: "/punters", component: Punters},
  { path: "/punter/:username", component: Punters},
  { path: "/save-bank", component: SaveBankDetails},
  { path: "/auto-trada", component: AutoTrada},
  { path: "/pot/:id", component: Trada},
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [

  { path: "/register", component: RegisterPage },
  { path: "/subscription", component: SubscriptionPage},
  { path: "/privacy-policy", component: PrivacyPolicy},
  { path: "/terms-and-conditions", component: TermsAndConditions},
  { path: "/verify-token", component: EmailVerify},
  { path: "/forgot-password", component: UserForgetPassword},
  { path: "/login", component: LoginUser},
  { path: "/create-pin", component: CreatePin},
  // { path: "/save-bank", component: SaveBankDetails},
  { path:"/referral/:referralCode", component: ReferralLink},
  { path: "/change-password", component: ChangePassword}
]

export { authProtectedRoutes, publicRoutes }

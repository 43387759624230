const groupA = [
    {
       "id": 1,
       "name": "1st Stake",
       "stake": 100
    },

    {
       "id": 2,
       "name": "2nd Stake",
       "stake": 120
    },

    {
       "id": 3,
       "name": "3rd Stake",
       "stake": 145
    },

    {
       "id": 4,
       "name": "4th Stake",
       "stake": 175
    },

    {
       "id": 5,
       "name": "5th Stake",
       "stake": 210
    },

    {
       "id": 6,
       "name": "6th Stake",
       "stake": 250
    },

    {
       "id": 7,
       "name": "7th Stake",
       "stake": 300
    },

    {
       "id": 8,
       "name": "8th Stake",
       "stake": 360
    },

    {
       "id": 9,
       "name": "9th Stake",
       "stake": 435
    },

    {
       "id": 10,
       "name": "10th Stake",
       "stake": 520
    },

    {
       "id": 11,
       "name": "11th Stake",
       "stake": 625
    },

    {
       "id": 12,
       "name": "12th Stake",
       "stake": 750
    },

    {
       "id": 13,
       "name": "13th Stake",
       "stake": 900
    },

    {
       "id": 14,
       "name": "14th Stake",
       "stake": 1080
    },

    {
       "id": 15,
       "name": "15th Stake",
       "stake": 1300
    },

    {
       "id": 16,
       "name": "16th Stake",
       "stake": 1560
    },

    {
       "id": 17,
       "name": "17th Stake",
       "stake": 1870
    },

    {
       "id": 18,
       "name": "18th Stake",
       "stake": 2240
    },

    {
       "id": 19,
       "name": "19th Stake",
       "stake": 2690
    },

    {
       "id": 20,
       "name": "20th Stake",
       "stake": 3230
    },

    {
       "id": 21,
       "name": "21st Stake",
       "stake": 3870
    },

    {
       "id": 22,
       "name": "22nd Stake",
       "stake": 4650
    },

    {
       "id": 23,
       "name": "23rd Stake",
       "stake": 5570
    },

    {
       "id": 24,
       "name": "24th Stake",
       "stake": 6690
    },

    {
       "id": 25,
       "name": "25th Stake",
       "stake": 8030
    },

    {
       "id": 26,
       "name": "26th Stake",
       "stake": 9630
    },

    {
       "id": 27,
       "name": "27th Stake",
       "stake": 11560
    },

    {
       "id": 28,
       "name": "28th Stake",
       "stake": 13870
    },

    {
       "id": 29,
       "name": "29th Stake",
       "stake": 16640
    },

    {
       "id": 30,
       "name": "30th Stake",
       "stake": 19970
    }
];


const groupB = [
    {
        "id": 1,
        "name": "1st Stake",
        "stake": 100
    },
    {
        "id": 2,
        "name": "2nd Stake",
        "stake": 120
    },
    {
        "id": 3,
        "name": "3rd Stake",
        "stake": 145
    },
    {
        "id": 4,
        "name": "4th Stake",
        "stake": 175
    },
    {
        "id": 5,
        "name": "5th Stake",
        "stake": 210
    },
    {
        "id": 6,
        "name": "6th Stake",
        "stake": 250
    },
    {
        "id": 7,
        "name": "7th Stake",
        "stake": 300
    },
    {
        "id": 8,
        "name": "8th Stake",
        "stake": 360
    },
    {
        "id": 9,
        "name": "9th Stake",
        "stake": 435
    },
    {
        "id": 10,
        "name": "10th Stake",
        "stake": 520
    },
    {
        "id": 11,
        "name": "11th Stake",
        "stake": 625
    },
    {
        "id": 12,
        "name": "12th Stake",
        "stake": 750
    },
    {
        "id": 13,
        "name": "13th Stake",
        "stake": 900
    },
    {
        "id": 14,
        "name": "14th Stake",
        "stake": 1080
    },
    {
        "id": 15,
        "name": "15th Stake",
        "stake": 1300
    },
    {
        "id": 16,
        "name": "16th Stake",
        "stake": 1560
    },
    {
        "id": 17,
        "name": "17th Stake",
        "stake": 1870
    },
    {
        "id": 18,
        "name": "18th Stake",
        "stake": 2240
    },
    {
        "id": 19,
        "name": "19th Stake",
        "stake": 2690
    },
    {
        "id": 20,
        "name": "20th Stake",
        "stake": 3230
    },
    {
        "id": 21,
        "name": "21st Stake",
        "stake": 3870
    },
    {
        "id": 22,
        "name": "22nd Stake",
        "stake": 4650
    },
    {
        "id": 23,
        "name": "23rd Stake",
        "stake": 5570
    },
    {
        "id": 24,
        "name": "24th Stake",
        "stake": 6690
    },
    {
        "id": 25,
        "name": "25th Stake",
        "stake": 8030
    }
];


const groupC = [
    {
        "id": 1,
        "name": "1st Stake",
        "stake": 100
    },
    {
        "id": 2,
        "name": "2nd Stake",
        "stake": 120
    },
    {
        "id": 3,
        "name": "3rd Stake",
        "stake": 145
    },
    {
        "id": 4,
        "name": "4th Stake",
        "stake": 175
    },
    {
        "id": 5,
        "name": "5th Stake",
        "stake": 210
    },
    {
        "id": 6,
        "name": "6th Stake",
        "stake": 250
    },
    {
        "id": 7,
        "name": "7th Stake",
        "stake": 300
    },
    {
        "id": 8,
        "name": "8th Stake",
        "stake": 360
    },
    {
        "id": 9,
        "name": "9th Stake",
        "stake": 435
    },
    {
        "id": 10,
        "name": "10th Stake",
        "stake": 520
    },
    {
        "id": 11,
        "name": "11th Stake",
        "stake": 625
    },
    {
        "id": 12,
        "name": "12th Stake",
        "stake": 750
    },
    {
        "id": 13,
        "name": "13th Stake",
        "stake": 900
    },
    {
        "id": 14,
        "name": "14th Stake",
        "stake": 1080
    },
    {
        "id": 15,
        "name": "15th Stake",
        "stake": 1300
    },
    {
        "id": 16,
        "name": "16th Stake",
        "stake": 1560
    },
    {
        "id": 17,
        "name": "17th Stake",
        "stake": 1870
    },
    {
        "id": 18,
        "name": "18th Stake",
        "stake": 2240
    },
    {
        "id": 19,
        "name": "19th Stake",
        "stake": 2690
    },
    {
        "id": 20,
        "name": "20th Stake",
        "stake": 3230
    }
];

const groupCTwo = [
    {
        "id": 1,
        "name": "1st Stake",
        "stake": 100
    },
    {
        "id": 2,
        "name": "2nd Stake",
        "stake": 100
    },
    {
        "id": 3,
        "name": "3rd Stake",
        "stake": 100
    },
    {
        "id": 4,
        "name": "4th Stake",
        "stake": 100
    },
    
]



export {
    groupA, 
    groupB,
    groupC,
    groupCTwo
}
import React from 'react'
import PropTypes from 'prop-types';
import Iconfiy from 'pages/Utility/Iconfiy'
import { Link } from "react-router-dom";
import CountdownToDate from './Countdown';
import NumberFormat from 'react-number-format';


const AutoTrada = ({trade_volume, participants, time_from, time_to, status, percentage, link}) => {
   
  return (
    <Link to={link}>
    <div className='temp-contain d-flex flex-row align-items-center' >
      <div className='spooIcon'>
         <Iconfiy icon="tdesign:saving-pot"  color="#5719D0"/>
      </div>

      <div style={{ flexGrow: "1" }}>
        <h4>  <NumberFormat value={trade_volume} className="foo" displayType={'text'} thousandSeparator={true} renderText={(value, props) => <div {...props}>&#8358;{value}</div>}/></h4>
        <div className='d-flex flex-column'>
            <div className="wallet-slider-container">
            <div className="d-flex overflow-x-auto all-wallets no-scrollbar" style={{ gap: '20px', overflowX: 'auto', scrollSnapType: 'x mandatory' }}>
                <span onClick={() => setFocusedPage("Today games") } style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "5px", fontWeight: "bold", color: "rgb(76 46 135)" }}><Iconfiy icon="ion:people-outline" width="15px" />{participants}</span>    

                {status === "Raising" ? (
                  <><span  style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "5px", fontWeight: "bold", color: "rgb(76 46 135)" }}><Iconfiy icon="ri:time-line" width="15px" /><CountdownToDate targetDate={time_to} /></span></>
                  ) : status === "Trading" ? (
                  <>
                   <span  style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "5px", fontWeight: "bold", color: "green" }}><Iconfiy icon="lets-icons:candlestick-light" width="15px" />Trading</span>
                  </>) : (
                    <>{status}</>
                    )}    
            </div>
            </div>
            </div>
        </div>

      <Iconfiy icon="ep:arrow-right"/>
    </div>
    </Link>
  )
}

export default AutoTrada


AutoTrada.propTypes = {
  trade_volume: PropTypes.any,
  participants: PropTypes.any,
  time_from: PropTypes.any,
  time_to: PropTypes.any,
  link: PropTypes.any,
  status: PropTypes.any,
  percentage: PropTypes.any,
}


  
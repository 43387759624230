import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useMemo } from "react";
import MetaTags from "react-meta-tags";
import { ToastContainer, toast } from 'react-toastify';
import 'moment-timezone';
import NumberFormat from 'react-number-format';
import Loader from "pages/Utility/Loader";
import TopGamesCard from "../Dashboard/TopGamesCard";
import Iconfiy from "pages/Utility/Iconfiy"
import GameCard from "components/Common/GameCard";
import sporty from "assets/images/images.png"
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import  shortenRelativeTime from "pages/Utility/time"
import TopCard from "components/Common/TopCard";


import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Label,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Button
} from "reactstrap";
import GameDetailsModal from "components/Common/GameDetailsModal";


const API_URL = process.env.REACT_APP_BASE_URL


const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use((req) => {
  if(localStorage.getItem('profile')){
      req.headers.Authorization = `Bearer ${ JSON.parse(localStorage.getItem('profile')).token}`,
      req.headers.Accept = 'application/json'
  }
  return req;
});



const puntersHub = props => {
  const [gameDetailModal, setGameDetailModal] = React.useState(false)
  const [gameDetails, setGameDetails] = React.useState()

  const [loading, setLoading] = React.useState(false)
  const [games,  setGames] = React.useState([])
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")))
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  

  const toggle = () => {
    setGameDetailModal(!gameDetailModal)
  }




  const openGameDetails = (game) => {
    setGameDetails(game)
    setGameDetailModal(true)
  }

 
 


 




  

  const getAllGames = async () => {
    setLoading(true);
    try {
      const collectionRef = firebase.firestore().collection('my-games')
        .where('user_id', '==', user?.data.id)
        .orderBy('timestamp', 'desc')
        .limit(5);
      
      let query;
      if (lastDoc) {
        query = collectionRef.startAfter(lastDoc);
      } else {
        query = collectionRef;
      }

      const snapshot = await query.get();
      const newGames = [];
      snapshot.forEach((doc) => {
            const data = doc.data();
            const timestamp = data.timestamp?.toDate(); // Convert Firestore timestamp to JS Date
            const formattedTimestamp = timestamp ? shortenRelativeTime(timestamp) : 'N/A'; // Format the date with moment.js
            newGames.push({ id: doc.id, ...data, formattedTimestamp });
      });

      setGames((prevGames) => [...prevGames, ...newGames]);
      setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
      setHasMore(snapshot.docs.length === 5);
    } catch (error) {
      toast.error('Error loading games');
      console.error('Error loading games:', error);
    } finally {
      setLoading(false);
    }
  }
  



  
  


  useEffect(() => {
    getAllGames()
  }, []);

 


  const loadMoreGames = () => {
    if (!loading && hasMore) {
      getAllGames()
    }
  };

  const copyToClipboard = () => {
    const gameCode = gameDetails?.game_code;
    navigator.clipboard.writeText(gameCode)
        .then(() => {
            toast.success('Code copied', gameCode)
            // You can also show a success message here if needed
        })
        .catch(err => {
            console.error('Failed to copy code to clipboard:', err);
            // You can also show an error message here if needed
        });
};

  

  return (
    <React.Fragment>
    <div className="page-content">
      <MetaTags>
        <title>Suretrada | My games</title>
      </MetaTags>
    
      
       <>
        <Container>
        <Row>
          <Col md="6">
             <TopCard  />
          </Col>

          
         
          <Col md="12" style={{ marginTop: "20px"}}>
            
          </Col>

          <Col md="6" style={{ marginTop: "20px"}}>
             <h6>All my games</h6>
              
              {
                games.length == 0 ? (
                <>
                  <Loader />
                  <p>Punters still cooking games.. Abeg wait small</p>
                </>
                ) : (
                  <>
                     {
                      games.map(game => (
                        <>
                        <GameCard key={game.id}     timestamp={game.formattedTimestamp}  title={game.title} punter={game.punter_name} status={game.status} amount={game.amount} onclick={() => openGameDetails(game)} platform={game.platform} />
                        </>
                      ))
                     }
                      {loading && <p>Loading...</p>}
                        {hasMore && !loading && (
                          <button className="active" style={{ padding: "10px", border: "none", backgroundColor: "#5719D0", color: "white", borderRadius: "5px"}} onClick={loadMoreGames}>Load more games</button>
                        )}
                  </>
                )
              }
            
          </Col>
         
        </Row>

         

            {/* Become a punter */}
            <Modal   isOpen={gameDetailModal}  toggle={toggle} size="md" centered={true}>
              {loading && <Loader  /> }
             
              <ModalHeader toggle={toggle} tag="h4" >Become a punter</ModalHeader>
                <ModalBody>
                <div style={{ borderRadius: "10px", border: "1px solid black", padding: "10px", marginTop:"20px"}}>
                  <span>Name</span> <span style={{ float: "right"}}>{gameDetails?.punter_name}</span> <br />
                  {/* <span>Total games posted</span> <span style={{ float: "right"}}>{gameDetails?.game_count}</span> <br /> */}
                  <span>Total games won</span> <span style={{ float: "right"}}>{gameDetails?.won_count}</span> <br />
                  <span>Total games lost</span> <span style={{ float: "right"}}>{gameDetails?.lost_count}</span> <br />
                  <hr />

                  <h5>Game Details</h5>
                  <span>Title</span> <span style={{ float: "right"}}>{gameDetails?.title}</span> <br />
                  <span>Price</span> <span style={{ float: "right"}}>{<NumberFormat  value={Math.round(gameDetails?.amount)}  className="foo" displayType={'text'} thousandSeparator={true} renderText={(value, props) => <div {...props}>&#8358;{value}</div>}   /> }</span> <br />
                  <span>Status</span> <span style={{ float: "right"}}>{gameDetails?.status}</span> <br />
                  <span>Total Odd</span> <span style={{ float: "right"}}>{gameDetails?.odd}</span> <br />
                  <span>Total Buyers</span> <span style={{ float: "right"}}>{gameDetails?.buyers_count}</span> <br />
                  <span>Platform</span> <span style={{ float: "right"}}>{gameDetails?.platform}</span> <br />
                  <span>Earlest game time</span> <span style={{ float: "right"}}>{gameDetails?.earliest_game_time}</span> <br />
                  { user?.data.id === gameDetails?.user_id &&  (<> <span>Expected Profit</span> <span style={{ float: "right"}}>{<NumberFormat  value={Math.round(gameDetails?.possible_profit)}  className="foo" displayType={'text'} thousandSeparator={true} renderText={(value, props) => <div {...props}>&#8358;{value}</div>}   /> }</span> <br /></>) }
                  <hr />
                  <span>Game Code</span> <span style={{ float: "right", fontSize: "20px", color: "#5719D0"}}>{gameDetails?.game_code} <Iconfiy color="red" onClick={copyToClipboard} icon="ph:copy"/> </span>
                   </div>
                </ModalBody>
              </Modal>
      
      </Container>
       </>
    
    </div>
  </React.Fragment>
  );
};


export default puntersHub;

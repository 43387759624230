import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'moment-timezone';
import Loader from "pages/Utility/Loader";
import TopGamesCard from "./TopGamesCard";
import Iconfiy from "pages/Utility/Iconfiy"
import GameCard from "components/Common/GameCard";
import { useHistory } from 'react-router-dom';

import axios from "axios";
import {
  Container,
  Row,
  Col,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Button
} from "reactstrap";
import GameDetailsModal from "components/Common/GameDetailsModal";
import  shortenRelativeTime from "pages/Utility/time"

const API_URL = process.env.REACT_APP_BASE_URL;

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use((req) => {
  if(localStorage.getItem('profile')){
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    req.headers.Accept = 'application/json';
  }
  return req;
});

const PuntersHub = () => {
  const [gameDetailModal, setGameDetailModal] = useState(false);
  const [gameDetails, setGameDetails] = useState(null);
  const [game_id, setGame_id] = useState("");
  const [addGameModal, setAddGameModal] = useState(false);

  const [totalOdd, setTotalOdd] = useState("");
  const [title, setTitle] = useState("");
  const [game_code, setGameCode] = useState("");
  const [platform, setPlatform] = useState("");
  const [game_start, setGameStart] = useState("");
  const [game_ends, setGameEnds] = useState("");

  const [loading, setLoading] = useState(false);
  const [topGames, setTopGames] = useState([]);
  const [amount, setAmount] = useState("");

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const [documentData, setDocumentData] = useState(null);
  const [becomePunterModal, setBecomePunterModal] = useState(false);

  const [games, setGames] = useState([]);
  const [filteredGames, setFilteredGames] = useState([]);
  const [activeFilter, setActiveFilter] = useState("Active");

  const history = useHistory();

  useEffect(() => {
    fetchGames();
  }, []);

  useEffect(() => {
    filterGames();
  }, [games, activeFilter]);

  const fetchGames = async () => {
    setLoading(true);
    try {
      const response = await axiosApi.get(`${API_URL}/api/v1/puntershub/get-betting-codes`);
      setGames(response.data);
    } catch (error) {
      console.error("Error fetching games:", error);
      toast.error("Failed to fetch games");
    } finally {
      setLoading(false);
    }
  };

  const filterGames = () => {
    const filtered = games.filter(game => game.status === activeFilter);
    setFilteredGames(filtered);
  };

  const toggle = () => {
    setDocumentData(null);
    setGameDetailModal(!gameDetailModal);
  };

  const addGameToggle = () => {
    setAddGameModal(!addGameModal);
  };

  const becomePunterToggle = () => {
    setBecomePunterModal(!becomePunterModal);  
  };

  const openGameDetails = (game) => {
    setGameDetails(game);
    setGame_id(game.id);
    setGameDetailModal(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const gamePayload = {
      odd: totalOdd,
      title,
      game_code,
      platform,
      amount,
      startDate: game_start,
      endDate: game_ends
    };
  
    try {
      await axiosApi.post(`${API_URL}/api/v1/puntershub/create-code`, gamePayload);
      toast.success("Game posted successfully");
      addGameToggle();
      fetchGames();
    } catch (error) {
      console.error("Error posting game: ", error);
      toast.error("Failed to post game");
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Suretrada | Buy and sell sure predictions</title>
        </MetaTags>
        <GameDetailsModal 
          game_id={game_id} 
          gameDetailModal={gameDetailModal} 
          setDocumentData={setDocumentData} 
          documentData={documentData} 
          toggle={toggle} 
          gameDetails={gameDetails} 
          user={user} 
        />
        
        <Container>
          <Row>
            <Col md="6">
              <TopGamesCard 
                games={topGames} 
                onpress={() => setBecomePunterModal(true)} 
                onclick={() => setAddGameModal(true)} 
              />
            </Col>
            
            <Col md="12" style={{ marginTop: "20px"}}>
              <div className='d-flex flex-column'>
                <div className="wallet-slider-container">
                  <div className="d-flex overflow-x-auto all-wallets no-scrollbar" style={{ gap: '20px', overflowX: 'auto', scrollSnapType: 'x mandatory' }}>
                    {["Active", "Boom", "Cut", "Ended"].map((status) => (
                      <span 
                        key={status}
                        onClick={() => setActiveFilter(status)} 
                        style={{ 
                          display: "flex", 
                          flexDirection: "row", 
                          alignItems: "center", 
                          gap: "5px", 
                          fontWeight: "bold", 
                          color: activeFilter === status ? "#5719D0" : "inherit",
                          cursor: "pointer"
                        }}
                      >
                        <Iconfiy icon={status === "Cut" || status === "Ended" ? "icon-park-outline:success" : "material-symbols:circle-outline"} width="15px" />
                        {status}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </Col>

            <Col md="6" style={{ marginTop: "20px"}}>
              <h6>Betting codes</h6>
              {loading && <Loader />}
              {filteredGames.length === 0 ? (
                <p>Sorry, no betting codes posted yet for this category. Check again later.</p>
              ) : (
                filteredGames.map(game => (
                  <GameCard 
                    key={game.id}  
                    rating={game.punting_power} 
                    timestamp={shortenRelativeTime(game.createdAt)}  
                    title={game.title} 
                    punter={game.punter_name} 
                    status={game.status} 
                    amount={game.amount} 
                    onclick={() => openGameDetails(game)} 
                    platform={game.platform} 
                  />
                ))
              )}
            </Col>
          </Row>

          {/* Add game modal */}
          <Modal isOpen={addGameModal} toggle={addGameToggle} size="md" centered={true}>
            {loading && <Loader />}
            <ModalHeader toggle={addGameToggle} tag="h4">Post game</ModalHeader>
            <ModalBody>
              <Form onSubmit={handleSubmit}>
                <Row form>
                  <Col xs={12}>
                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <Label className="form-label">Title</Label>
                          <Input
                            name="title"
                            type="text"
                            placeholder="2 Odds draw"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <Label className="form-label">Code</Label>
                          <Input
                            name="game_code"
                            type="text"
                            placeholder="78AD67"
                            value={game_code}
                            onChange={(e) => setGameCode(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <Label className="form-label">Total Odds</Label>
                          <Input
                            name="total_odds" 
                            type="text"
                            placeholder="2.50"
                            value={totalOdd}
                            onChange={(e) => setTotalOdd(e.target.value)}
                            className={totalOdd === '' || /^\d*\.?\d*$/.test(totalOdd) ? 'form-control' : 'form-control is-invalid'}
                          />
                          {totalOdd !== '' && !/^\d*\.?\d*$/.test(totalOdd) && <div className="invalid-feedback">Please enter a valid float value (e.g., 3.50)</div>}
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <Label className="form-label">Platform</Label>
                          <Input
                            type="select"
                            name="platform"
                            value={platform}
                            onChange={(e) => setPlatform(e.target.value)}
                          >
                            <option value="">Select Platform</option>
                            <option value="sportybet">SportyBet</option>
                            <option value="bet9ja">Bet9ja</option>
                            <option value="1xbet">1X Bet</option>
                            <option value="nairabet">Nairabet</option>
                          </Input>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12">
                        <div className="mb-3">
                          <Label className="form-label">Amount to sell</Label>
                          <Input
                            type="select"
                            name="amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                          >
                            <option value="">Select amount</option>
                            {user?.data.can_monetize_game ? (
                              <>
                                <option value="0">₦0</option>
                                <option value="100">₦100</option>
                                <option value="200">₦200</option>
                                <option value="500">₦500</option>
                              </>
                            ) : (
                              <option value="0">₦0</option>
                            )}
                          </Input>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <Label className="form-label">Game Start</Label>
                          <Input
                            name="game_start" 
                            type="datetime-local"
                            value={game_start}
                            onChange={(e) => setGameStart(e.target.value)}
                          />
                        </div>
                      </Col> 

                      <Col md="6">
                        <div className="mb-3">
                          <Label className="form-label">Game Ends</Label>
                          <Input
                            name="game_ends" 
                            type="datetime-local"
                            value={game_ends}
                            onChange={(e) => setGameEnds(e.target.value)}
                          />
                        </div>
                      </Col> 
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        type="submit"
                        color="primary"
                        disabled={!game_code || !totalOdd || !/^\d*\.?\d*$/.test(totalOdd) || !title || !amount || !game_start || !game_ends || !platform}
                      >
                        Post game
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>

          {/* Become a punter modal */}
          <Modal isOpen={becomePunterModal} toggle={becomePunterToggle} size="md" centered={true}>
            {loading && <Loader />}
            <ModalHeader toggle={becomePunterToggle} tag="h4">Become a punter</ModalHeader>
            <ModalBody>
              <p>Wait first we dey come!</p>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PuntersHub;
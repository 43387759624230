import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";

import NumberFormat from 'react-number-format';
import ProgressBar from "@ramonak/react-progress-bar"
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  ModalBody
} from "reactstrap";

import Services from "../../components/Common/Services"

const API_URL = process.env.REACT_APP_BASE_URL


const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use((req) => {
  if(localStorage.getItem('profile')){
      req.headers.Authorization = `Bearer ${ JSON.parse(localStorage.getItem('profile')).token}`,
      req.headers.Accept = 'application/json'
  }
  return req;
});




const Punters = props => {
  const [modal, setModal] = useState(false)
  const [transaction, setTransaction] = useState()


  const getUserWallet = async () => {
    axiosApi.get(`${API_URL}/api/v1/user/naira-wallet`).then((res) => {
      setBalance(res.data.balance)
    }).catch((error) => {
     console.log(error)
    })
  }
  

  
  
  const toggle = () => {
    setShow(!show)
  }
  
  useEffect(() => {
    getUserWallet()
  }, [])



const toggleTrans = () => {
  setModal(!modal)
}
  







  return (
    <React.Fragment>
      
      <div className="page-content">
        <MetaTags>
          <title>SureTrada | Punters where sabi  </title>
        </MetaTags>
        <Container style={{ justifyContent: "center"}}>
           <Row>
             <Col md="10">
               <Row>
               <Col md="5">
                 <Row>
                    <Col md="12">
                     
    
                    </Col>

                    <Col md="12" style={{ backgroundColor: "white", paddingTop: "20px"}}>
                      <h3>Our services</h3> 
                      <hr />
                      <div className="mobile">
                          <Services id={3} title="Punters hub" details="Buy and sell games" link="/punters-hub"  />
                          <Services id={4} title="Over 4.5 signal" details="Virtual signal" link="/signal-4"  />
                          <Services id={5} title="Stake calculator" details="calculate stake amount based on odd" link="/stake-calculator"  />
                          <Services id={6} title="Over 2.5 calculator" details="Always play Over 2.5" link="/overtwofivecalculator"  />
                          <Services id={7} title="How to" details="Learn how to use our tools" link="/how-to"  />
                      </div>
                    
                     <hr />

                     <Services id={1} title="My profile" details="View your account details." link="/profile"  />
                     <Services id={2} title="My Games" details="See all your games." link="/my-games"  />

                    </Col>
                 </Row>
             
                  </Col>
                  
               </Row>
             </Col>
           </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Punters.propTypes = {
  t: PropTypes.any,
  userDetails: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default Punters

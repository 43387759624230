import { Container } from 'reactstrap'
import React from 'react'
import Iconfiy from "pages/Utility/Iconfiy"


const TopCard = () => {
    return (
        <>
        <div className='d-flex flex-column wallet-view' style={{ gap: '20px', backgroundColor: "rgb(40 10 99)", height: "100px" }}>
          <div className='d-flex justify-content-between align-items-center wallet-atn'>
            <div className='d-flex align-items-center' style={{ gap: '10px' }}>
               <h4 style={{ color: "white"}}>All your games in one place</h4>
                <Iconfiy icon="fluent:sport-16-regular" height="70px"  style={{  marginBottom: "10px", color: "green"}}/>
          </div>
          </div>
        </div>
        </>
    )
}

export default TopCard
import PropTypes from "prop-types";
import React from 'react'
import { MetaTags } from 'react-meta-tags'
import { Container } from 'reactstrap'
import Logo from "../../assets/images/logo.png"

const TermsAndConditions = props => {
  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>SureTrada | Terms and Conditions</title>
        </MetaTags>

        <Container>
          <div className='main-contentyu container-fluid d-flex justify-content-center'>
            <div className='major-width'>
            <a href="/dashboard">
              <div className='text-center migi'>
                 <img src={Logo} alt=""  height={60} />
            
              </div>
              </a>
              <div>
                <h3>Terms and Conditions for Suretrada</h3>
                <p>
                  These Terms and Conditions govern your use of Suretrada trade calculation and tools (Services) provided by SureTrada. By accessing or using Suretrada, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these Terms and Conditions, you may not use Suretrada.
                </p>
                <p>
                1. <span style={{ fontWeight: "bold"}}>License:</span> 
                   Subject to these Terms and Conditions, SureTrada grants you a limited, non-exclusive, non-transferable license to use Suretrada solely for your personal or internal business purposes.
                </p>
                <p>
                2. <span style={{ fontWeight: "bold"}}>RestrictionsYou</span>You may not:<br />
                     . Modify, adapt, translate, reverse engineer, or create derivative works of Suretrada; <br />
                     . Remove any copyright, trademark, or other proprietary rights notices from Suretrada;<br />
                     . Use Suretrada for any unlawful purpose or in violation of any applicable laws or regulations; <br />
                     . Share or distribute Suretrada to any third party without prior written consent from SureTrada. <br />
                </p>

                <p>
                    3. <span style={{ fontWeight: "bold"}}>Intellectual Property: </span>
                    Suretrada and all content, features, and functionality thereof are owned by SureTrada and are protected by copyright, trademark, and other intellectual property laws. You may not use any trademarks or logos of Suretrada without prior written permission from SureTrada.
                </p>

                <p>
                   4. <span style={{ fontWeight: "bold"}}>Disclaimer of Warranties: </span>
                   Suretrada is provided on an &apos;as &apos;is and &apos;as available&apos; basis, without any warranties of any kind, either express or implied. SureTrada does not warrant that Suretrada will be uninterrupted, error-free, or free from harmful components.
                </p>

                <p>
                   5. <span style={{ fontWeight: "bold"}}>Limitation of Liability: </span>
                   SureTrada shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or relating to your use of Suretrada, even if SureTrada has been advised of the possibility of such damages.
                </p>


                <p>
                   6. <span style={{ fontWeight: "bold"}}>Indemnification: </span>
                   You agree to indemnify and hold [Your Company Name] harmless from any claims, damages, liabilities, costs, and expenses (including reasonable attorneys&apos; fees) arising out of or related to your use of Suretrada or your violation of these Terms and Conditions.
                </p>

              

                <p>
                   7. <span style={{ fontWeight: "bold"}}>Governing Law: </span>
                   These Terms and Conditions shall be governed by and construed in accordance with the laws of SureTrada, without regard to its conflict of law principles.
                </p>

                <p>
                   8. <span style={{ fontWeight: "bold"}}>Changes to Terms and Conditions: </span>
                    SureTrada reserves the right to update or modify these Terms and Conditions at any time without prior notice. Your continued use of Suretrada after any such changes constitutes your acceptance of the revised Terms and Conditions.
                </p>


                <p>
                   9. <span style={{ fontWeight: "bold"}}>Contact Us: </span>
                   If you have any questions or concerns about this terms and conditions or our data practices, please contact us via email @ suretrada@gmail.com or phone number +234 7032588139.
                </p>


              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TermsAndConditions

import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import  shortenRelativeTime from "pages/Utility/time"
import Iconfiy from "pages/Utility/Iconfiy"
import NumberFormat from 'react-number-format';
import ProgressBar from "@ramonak/react-progress-bar"
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap";


import AutoTradaCom from "components/Common/AutoTrada";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import GeneralCard from "components/Common/GeneralCard"


const API_URL = process.env.REACT_APP_BASE_URL


const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use((req) => {
  if(localStorage.getItem('profile')){
      req.headers.Authorization = `Bearer ${ JSON.parse(localStorage.getItem('profile')).token}`,
      req.headers.Accept = 'application/json'
  }
  return req;
});




const AutoTrada = props => {

  const [showBalance, setShowBalance] = React.useState(false);
  const [balance, setBalance] = useState(0)
  const [modal, setModal] = useState(false)
  const [pots, setPots] = useState([])
  const [learnMore, setLearnMore] = useState(false)
  const [howTo, setHowTo] = useState(false)
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")))

 
  
  
  
  const toggle = () => {
    setModal(!modal)
  }

  const toggleLearnMore = () => {
    setLearnMore(!learnMore)
  }

  const toogleHowTo = () => {
    setHowTo(!howTo)
  }
  
  



const toggleBalanceVisibility = () => {
    setShowBalance((prevShowBalance) => !prevShowBalance);
  };

  const getPots = async () => {
     // Create a reference to the Firestore collection
     const collectionRef = firebase.firestore().collection('trading_pots');

     // Subscribe to real-time updates and order by timestamp
     const unsubscribe = collectionRef.orderBy('timestamp', 'desc').onSnapshot((snapshot) => {
       const newData = snapshot.docs.map((doc) => {
         const docData = doc.data();
         const timestamp = docData.timestamp?.toDate(); // Convert Firestore timestamp to JS Date
         const formatedRaisingTo = docData.raising_to?.toDate();
         const formattedTimestamp = timestamp ? shortenRelativeTime(timestamp) : 'N/A'; // Format the date with moment.js
         return { id: doc.id, ...docData, formattedTimestamp, formatedRaisingTo };
       });
     
       // Calculate the total sum of pot_volume
       const totalVolume = newData.reduce((acc, pot) => acc + (pot.pot_volume || 0), 0);
 
       setPots(newData);
       setBalance(totalVolume);
       console.log(newData);
     });
 
     // Cleanup subscription on component unmount
     return () => unsubscribe();
  }

  useEffect(() => {
    getPots()
  }, []);
  



const balanceCard = () => {
    return (
        <>
   <Card style={{ borderRadius: "10px", backgroundColor: "rgb(76 46 135)"}}>
      <div className="text-center" style={{ paddingTop: "30px"}}>
        <h5 style={{ color: "#ffff"}}>Pots Volume</h5>
        <div className='d-flex flex-row justify-content-center align-items-center'>
          <h2 style={{ color: "#fff", fontWeight: "bolder"}}>
             {
              showBalance ? (
              <>
              <NumberFormat value={balance} className="foo" displayType={'text'} thousandSeparator={true} renderText={(value, props) => <div {...props}>&#8358;{value}</div>}/> 
              </>) : (
              <>
               <span>₦{"*".repeat(balance.length - 3) + "." + '*'.repeat(2)}</span>
              </>)
             }
            </h2>
               <div style={{ marginLeft: '7px',cursor: 'pointer', color: "#fff" }} onClick={toggleBalanceVisibility}>
               <Iconfiy icon={showBalance ? "ion:eye-outline" : "ion:eye-off-outline"}/>
          </div>
        </div>
      </div>
    
    
      

      <div style={{ paddingBottom: "10px", margin: "auto", marginTop: "15px", overflowX: "auto", maxWidth: "100" }}>     
        <button onClick={toggle} className='btn btn-default temp-contain' style={{ border: "1px solid black", backgroundColor: "#000", color: "white", marginLeft: "2px" }}><Iconfiy icon="ic:round-plus" width="13px" /> T&Cs Apply</button>
        <button onClick={toogleHowTo} className='btn btn-default temp-contain' style={{ border: "1px solid black", backgroundColor: "#000", color: "white", marginLeft: "2px" }}><Iconfiy icon="ic:round-plus" width="13px" />How To</button>
    </div>


      
     </Card>
        </>
    )
}



  return (
    <React.Fragment>
      
      <div className="page-content">
        <MetaTags>
          <title>SureTrada | Auto-trada </title>
        </MetaTags>
        <Container style={{ justifyContent: "center"}}>
           <Row>
             <Col md="10">
               <Row>
               <Col md="8">
                 <Row>
                    <Col md="12">
                         {balanceCard()}
                      
                    </Col>

                    <div className='d-flex flex-column' style={{ paddingTop: "10px"}}>
                        <div className="wallet-slider-container">
                        <div className="d-flex overflow-x-auto all-wallets no-scrollbar" style={{ gap: '20px', overflowX: 'auto', scrollSnapType: 'x mandatory' }}>
                        <span onClick={() => setFocusedPage("Today games") } style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "5px", fontWeight: "bold", color: "rgb(76 46 135)" }}><Iconfiy icon="fluent:arrow-growth-20-filled" width="15px" />Raising</span>
                        <span onClick={() => setFocusedPage("Yesterday games") } style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "5px", fontWeight: "bold", color: "rgb(76 46 135)" }}><Iconfiy icon="hugeicons:trade-up" width="15px" />Trading</span>
                        <span onClick={() => setFocusedPage("Free games")} style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "5px", fontWeight: "bold", color: "rgb(76 46 135)" }}><Iconfiy icon="tdesign:saving-pot" width="15px" />My Pots</span>
                       
                        </div>
                        </div>
                     </div>

                


                    <Col md="12" style={{ backgroundColor: "white", paddingTop: "20px", borderRadius: "10px"}}>
                      <GeneralCard title="Get 5% referal bonus" details="Learn more" onclick={() => setLearnMore(true)}/>
                      <h3>Available Pots</h3> 
                    
                      {pots.length > 0 ? (
                        <>
                          {pots.map(item => (
                          <>
                               <AutoTradaCom trade_volume={item.pot_volume} time_to={item.formatedRaisingTo} participants={item.investors_count} percentage={item.percentage} link={`/pot/${item.id}`} status={item.status}  />
                          </>

                          ))}
                        </>
                        ) : (
                        <>
                          <p style={{ textAlign: "center"}}>No active pot at the moment..</p>
                        </>
                        )}
                      
                    </Col>
                 </Row>
             
                   </Col>
                   
               </Row>
             </Col>
           </Row>

           <Modal   isOpen={modal} toggle={toggle}  size="md" centered={true} >
           <ModalHeader toggle={toggle} tag="h4" >T&Cs</ModalHeader>
              <ModalBody className="py-3 px-5">
              <h1>Terms and Conditions</h1>
                <p>Last updated: [2024-06-24]</p>

                <h2>1. Introduction</h2>
                <p>Welcome to suretrada (&#34;Company&#34;, &#34;we&#34;, &#34;our&#34;, &#34;us&#34;)! These Terms and Conditions (&#34;Terms&#34;, &#34;Terms and Conditions&#34;) govern your use of our website located at [suretrada.com] (together or individually &#34;Service&#34;) operated by [Suretrada].</p>
                <p>Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard and disclose information that results from your use of our web pages.</p>
                <p>Your agreement with us includes these Terms and our Privacy Policy (&#34;Agreements&#34;). You acknowledge that you have read and understood Agreements, and agree to be bound by them.</p>

               
                <h2>2. Funding</h2>
                <p>If you wish to wish to join any pot, you would need to fund your suretrada wallet via the online top-up method.</p>
                <p>You represent and warrant that: (i) you have the legal right to use any card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.</p>

                <h2>3. Join Pot</h2>
                <p>Joining a pot is free as long as you have the amount to join a pot with, every pot has a Minimum amount to join with, so do well to check before proceeding.</p>
                <p>While raising funds for trading, You can not withdraw after joining till the due date.</p>
                <p>Payout is only allowed on the due date.</p>
                <p>Always check trade start date to know when trading will start and end for a particular pot before joining.</p>
               
                <h2>4. Withdrawal</h2>
                <p>When is due date, you can click on the PAYOUT button to move your earning and capital to your main wallet, which can be withdrawn from there to your Nigerian bank account.</p>
               
                <h2>5. Contact Us</h2>
                <p>If you have any complain or issues using our platform, kindly click on the WHATSAPP icon located at the bottom right of the site to chat with the support.</p>
                              
               </ModalBody>
              </Modal>


           <Modal   isOpen={learnMore} toggle={toggleLearnMore}  size="md" centered={true} >
               <ModalHeader toggle={toggleLearnMore} tag="h6" >5% referral bonus</ModalHeader>
               <ModalBody>
                <h2>1. 2.5% instant bonus</h2>
                <p>When you refer a person to join a pot, you get 2.5% of the total invested amount to your wallet which can be withdrawn at anytime.</p>
              
                <h2>2. 2.5% trading bonus</h2>
                <p>After the pot trading is due, you get a 2.5% of the person&#39;s total invested capital to your wallet which can be withdrawn at anytime.</p>         
               </ModalBody>
           </Modal>


            <Modal   isOpen={howTo} toggle={toogleHowTo}  size="md" centered={true} >
               <ModalHeader toggle={toogleHowTo} tag="h6" >How to join pot</ModalHeader>
               <ModalBody>
               <h2>Step 1</h2>
                <p>Go to <span>Dashboard</span> and click on the <span>Top-Up</span> button, you will be taken to the payment page, make your payment and your wallet will be funded.
                 <span>Note: If you wish to fund more than ₦50,000, please contact our customer support via WhatsApp, live chat, or email at contact@suretrada.com.</span>
                </p>
              
                <h2>Step 2</h2>
                <p>After wallet has been funded, click on the Auto-Trada card on the dashboard, then click on the pot you wish to join and you will be redirected to the pot page.
                  Next click on the <span>Join</span> button and input the amount you wish to join with.
                  </p>         
               </ModalBody>
           </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

AutoTrada.propTypes = {
  t: PropTypes.any,
  userDetails: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default AutoTrada

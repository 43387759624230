// SummaryStatistics.js
import React from 'react';


const SummaryStatistics = ({ games }) => {
  const totalGames = games.length;
  const totalGoals = games.reduce((sum, game) => sum + game.homeScore + game.awayScore, 0);
  const averageGoals = totalGames > 0 ? (totalGoals / totalGames).toFixed(2) : 0;
  const homeWins = games.filter(game => game.homeScore > game.awayScore).length;
  const awayWins = games.filter(game => game.awayScore > game.homeScore).length;
  const draws = games.filter(game => game.homeScore === game.awayScore).length;

  const over45 = games.filter(game => game.homeScore + game.awayScore > 4.5).length;
  const under05 = games.filter(game => game.homeScore + game.awayScore < 0.5).length;
  const over25 = games.filter(game => game.homeScore + game.awayScore > 2.5).length;
  const goalGoal = games.filter(game => game.homeScore > 0 && game.awayScore > 0).length;
  const noGoal = games.filter(game => game.homeScore === 0 || game.awayScore === 0).length;

  // Function to calculate correct score occurrences
  const getScoreCount = (homeScore, awayScore) => {
    return games.filter(game => game.homeScore === homeScore && game.awayScore === awayScore).length;
  };

  // Define all the correct scores we want to display
  const correctScores = [
    [0, 0], [1, 1], [2, 2], [3, 3],
    [1, 0], [2, 0], [2, 1], [3, 0], [3, 1], [3, 2],
    [4, 0], [4, 1], [4, 2], [5, 0], [5, 1], [6, 0],
    [0, 1], [0, 2], [1, 2], [0, 3], [1, 3], [2, 3],
    [0, 4], [1, 4], [2, 4], [0, 5], [1, 5], [0, 6]
  ];

  return (
    <div className="summary-statistics">
      <h2 className="summary-title">Summary Statistics</h2>
      
      <div className="stat-category">
        <h3 className="category-title">Correct Scores</h3>
        <div className="stats-grid correct-scores-grid">
          {correctScores.map(([home, away]) => (
            <StatItem 
              key={`${home}-${away}`}
              label={`${home}:${away}`}
              value={`${getScoreCount(home, away)} (${((getScoreCount(home, away) / totalGames) * 100).toFixed(1)}%)`}
            />
          ))}
        </div>
      </div>

      <div className="stat-category">
        <h3 className="category-title">Average</h3>
        <div className="stats-grid">
          <StatItem label="Average Goals" value={averageGoals} />
          <StatItem label="Home Wins" value={`${homeWins} (${((homeWins / totalGames) * 100).toFixed(1)}%)`} />
          <StatItem label="Away Wins" value={`${awayWins} (${((awayWins / totalGames) * 100).toFixed(1)}%)`} />
          <StatItem label="Draws" value={`${draws} (${((draws / totalGames) * 100).toFixed(1)}%)`} />
        </div>
      </div>

      <div className="stat-category">
        <h3 className="category-title">Goals</h3>
        <div className="stats-grid">
          <StatItem label="Total Games" value={totalGames} />
          <StatItem label="Total Goals" value={totalGoals} />
          <StatItem label="Goal:Goal" value={`${goalGoal} (${((goalGoal / totalGames) * 100).toFixed(1)}%)`} />
          <StatItem label="No Goal" value={`${noGoal} (${((noGoal / totalGames) * 100).toFixed(1)}%)`} />
          <StatItem label="Total GG" value={goalGoal} />
          <StatItem label="Total NG" value={noGoal} />
        </div>
      </div>

      <div className="stat-category">
        <h3 className="category-title">Over/Under</h3>
        <div className="stats-grid">
          <StatItem label="Over 4.5" value={`${over45} (${((over45 / totalGames) * 100).toFixed(1)}%)`} />
          <StatItem label="Under 0.5" value={`${under05} (${((under05 / totalGames) * 100).toFixed(1)}%)`} />
          <StatItem label="Over 2.5" value={`${over25} (${((over25 / totalGames) * 100).toFixed(1)}%)`} />
        </div>
      </div>
    </div>
  );
};

const StatItem = ({ label, value }) => (
  <div className="stat-item">
    <span className="stat-label">{label}</span>
    <span className="stat-value">{value}</span>
  </div>
);

SummaryStatistics.propTypes = {
  games: PropTypes.arrayOf(
    PropTypes.shape({
      homeScore: PropTypes.number.isRequired,
      awayScore: PropTypes.number.isRequired,
    })
  ).isRequired,
};

StatItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default SummaryStatistics;
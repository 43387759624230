import React from 'react'
import PropTypes from 'prop-types';
import Iconfiy from 'pages/Utility/Iconfiy'
import { Link } from "react-router-dom";
import CountdownToDate from './Countdown';
import NumberFormat from 'react-number-format';


const PotCom = ({user_name, amount, roi, status}) => {

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  
   
  return (

    <div className='temp-contain d-flex flex-row align-items-center' >
      <div className='spooIcon'>
         <Iconfiy icon="fluent-mdl2:profile-search"  color="#5719D0"/>
      </div>

      <div style={{ flexGrow: "1" }}>
        <h4>{capitalizeFirstLetter(user_name)} <span style={{ color: "grey"}}>({status})</span></h4>
        <div className='d-flex flex-column'>
            <div className="wallet-slider-container">
            <div className="d-flex overflow-x-auto all-wallets no-scrollbar" style={{ gap: '20px', overflowX: 'auto', scrollSnapType: 'x mandatory' }}>
                <span  style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "5px", fontWeight: "bold", color: "rgb(76 46 135)" }}><NumberFormat value={amount} className="foo" displayType={'text'} thousandSeparator={true} renderText={(value, props) => <div {...props}>&#8358;{value}</div>}/></span>    
                <span style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "5px", fontWeight: "bold", color: "rgb(76 46 135)" }}>ROI <NumberFormat value={roi} className="foo" displayType={'text'} thousandSeparator={true} renderText={(value, props) => <div {...props}>&#8358;{value}</div>}/></span>    
               
            </div>
            </div>
            </div>
        </div>

      <Iconfiy icon="ep:arrow-right"/>
    </div>
  )
}

export default PotCom


PotCom.propTypes = {
  user_name: PropTypes.any,
  amount: PropTypes.any,
  status: PropTypes.any,
  roi: PropTypes.any,
}


  
import moment from 'moment';

const shortenRelativeTime = (timestamp) => {
  const now = moment();
  const time = moment(timestamp);

  const diffInSeconds = now.diff(time, 'seconds');
  if (diffInSeconds < 60) {
    return 'now';
  }

  if (time.isSame(now, 'day')) {
    return time.fromNow().replace('minutes', 'min')
                        .replace('minute', 'min')
                        .replace('hours', 'hr')
                        .replace('hour', 'hr')
                        .replace('days', 'd')
                        .replace('day', 'd')
                        .replace('weeks', 'wk')
                        .replace('week', 'wk')
                        .replace('months', 'mo')
                        .replace('month', 'mo')
                        .replace('years', 'yr')
                        .replace('year', 'yr');
  } else if (time.isSame(now.subtract(1, 'day'), 'day')) {
    return 'yesterday';
  } else {
    return time.fromNow().replace('minutes', 'min')
                        .replace('minute', 'min')
                        .replace('hours', 'hr')
                        .replace('hour', 'hr')
                        .replace('days', 'd')
                        .replace('day', 'd')
                        .replace('weeks', 'wk')
                        .replace('week', 'wk')
                        .replace('months', 'mo')
                        .replace('month', 'mo')
                        .replace('years', 'yr')
                        .replace('year', 'yr');
  }
};

export default shortenRelativeTime;

import PropTypes from "prop-types";
import React from 'react'
import { MetaTags } from 'react-meta-tags'
import { Container } from 'reactstrap'
import Logo from "../../assets/images/logo.png"

const Privacy = props => {
  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>SureTrada | Privacy Policies </title>
        </MetaTags>

        <Container>
          <div className='main-contentyu container-fluid d-flex justify-content-center'>
            <div className='major-width'>
              <a href="/dashboard">
              <div className='text-center migi'>
                 <img src={Logo} alt=""  height={60} />
            
              </div>
              </a>
            

              <div>
                <h3>Privacy Policy for Suretrada</h3>
                <p>
                  This Privacy Policy describes how SureTrada collects, uses, and discloses information when you use our trade calculation and tools
                  {/* Add your Privacy Policy text here */}
                </p>
                <p>
                1. Information We Collect: We may collect the following types of information when you use Suretrada: <br />
                    <span style={{ fontWeight: "bold"}}>. Personal Information:</span> We may collect personal information such as your name, email address, bank account, and contact details when you voluntarily provide it to us for registration or communication purposes. <br />
                    <span style={{ fontWeight: "bold"}}>. Usage Data: </span> Suretrada may collect information about how you use the software, including your interactions, preferences, and settings.
                </p>
                <p>
                2. <span style={{ fontWeight: "bold"}}>How We Use Information: </span>Suretrada may use the collected information for the following purposes:<br />
                     . To provide and maintain the Service; <br />
                     . To personalize your experience and improve our software; <br />
                     . To communicate with you, respond to inquiries, and provide customer support; <br />
                     . To analyze usage trends and optimize the performance of Suretrada. <br />
                </p>

                <p>
                    3. <span style={{ fontWeight: "bold"}}>Data Sharing and Disclosure: </span>DisclosureWe do not sell, trade, or rent your personal information to third parties. <br />
                    However, we may share your information with trusted third-party service providers who assist us in operating Suretrada, conducting our business, or servicing you.
                </p>

                <p>
                   4. <span style={{ fontWeight: "bold"}}>Data Security: </span>
                   Suretrada takes reasonable measures to protect the security of your personal information and prevent unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
                </p>

                <p>
                   5. <span style={{ fontWeight: "bold"}}>Data Retention: </span>
                   Suretrada will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
                </p>


                <p>
                   6. <span style={{ fontWeight: "bold"}}>Your Rights: </span>
                   You may have certain rights regarding your personal information, including the right to access, correct, or delete your data. You may also have the right to opt-out of certain data processing activities. Please contact us if you wish to exercise these rights.
                </p>

            

                <p>
                   7. <span style={{ fontWeight: "bold"}}>Changes to This Privacy Policy: </span>
                   Suretrada may update this Privacy Policy from time to time by posting the revised version on our website. We encourage you to review this Privacy Policy periodically for any changes.
                </p>

                <p>
                   8. <span style={{ fontWeight: "bold"}}>Contact Us: </span>
                   If you have any questions or concerns about this Privacy Policy or our data practices, please contact us via email @ suretrada@gmail.com or phone number +234 7032588139.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Privacy
